import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

interface IBackButtonProps {
  style?: React.CSSProperties;
  to: string;
}

const BackButton = ({ style, to }: IBackButtonProps) => {
  return (
    <Link to={to}>
      <Button style={style}>
        <ArrowBackIcon />
      </Button>
    </Link>
  );
};

export default BackButton;
