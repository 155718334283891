import { AxiosResponse } from 'axios';
import axiosPrivate from '../api/axios';
import { IAuthResponse } from '../global/types/types';
import { ISingleUserResponse } from './userService';

export const loginRequest = async (email: string, password: string): Promise<AxiosResponse<IAuthResponse>> => {
  return await axiosPrivate.post<IAuthResponse>('/auth/login', { email, password });
};

export const verifyAccessToken = async (): Promise<AxiosResponse> => {
  return await axiosPrivate.get('auth/validate-token');
};

export const register = async (id: string, password: string): Promise<AxiosResponse<ISingleUserResponse>> => {
  return await axiosPrivate.post<ISingleUserResponse>(`/auth/register/${id}`, { password });
};
