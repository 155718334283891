import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table, { Column } from '../../components/Table';
import { Box, Button, CircularProgress, Grid, SelectChangeEvent, TablePagination, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { outputStatuses, outputTypes, outputsColumns } from '../../global/constants/outputs';
import { getOutputsAction, TSortParams } from '../../redux/actions/outputsActionCreators/actionCreators';
import {
  isOutputsLoading,
  outputsAmountSelector,
  outputsSelector,
  outputsTablePageSelector,
} from '../../redux/selectors/outputsSelector';
import DropdownSelect from '../../components/Select';
import Search from '../../components/Seach';
import { capitalizeFirstLetter, convertFunction } from '../../utils';
import OutputCreateModal from '../../components/OutputCreateModal';
import { rolesSelector } from '../../redux/selectors/rolesSelector';
import { roleSelector } from '../../redux/selectors/authSelector';
import { rolesActions } from '../../redux/actions/rolesActionCreators/actionCreators';
import { Roles } from '../../global/types/types';
import { OutputsTableColumns } from '../../global/types/outputs-types';
import { customersActions } from '../../redux/actions/customersActionCreators/actionCreators';
import { customersSelector } from '../../redux/selectors/customersSelector';

interface ISortParam {
  id: OutputsTableColumns;
  sort: TSortParams;
}

const OutputsPage = () => {
  const role = useSelector(roleSelector);
  const outputs = useSelector(outputsSelector);
  const isLoading = useSelector(isOutputsLoading);
  const fetchedCompanies = useSelector(customersSelector);
  const outputsAmount = useSelector(outputsAmountSelector);
  const outputsTablePage = useSelector(outputsTablePageSelector);
  const fetchedRoles = useSelector(rolesSelector);

  const dispatch = useDispatch();

  const [companyFilter, setCompanyFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [searched, setSearched] = useState('');
  const [page, setPage] = useState(outputsTablePage);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columns, setColumns] = useState(outputsColumns);
  const [sortOrder, setSortOrder] = useState<ISortParam[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const companies = useMemo(
    () =>
      fetchedCompanies
        .map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [fetchedCompanies]
  );
  const roles = useMemo(
    () =>
      fetchedRoles
        .filter((item) => item.name !== Roles.SUPERADMIN)
        .map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() })),
    [fetchedRoles]
  );
  const sortParams = useMemo(() => [...sortOrder].reverse(), [sortOrder]);
  const statuses = useMemo(() => convertFunction(outputStatuses), []);
  const types = useMemo(() => convertFunction(outputTypes), []);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(customersActions.getCustomersList({ isArray: true }));
    dispatch(rolesActions.getRolesList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getOutputsAction({
        search: searched,
        sort: sortParams,
        page,
        rowPerPage: rowsPerPage,
        companyId: companyFilter,
        status: statusFilter,
      })
    );
  }, [companyFilter, statusFilter, searched, page, rowsPerPage, dispatch, sortParams]);

  const onCancelSearch = useCallback(() => {
    setSearched('');
    setPage(0);
  }, []);

  const onCompanySelectChange = useCallback((e: SelectChangeEvent) => {
    setCompanyFilter(e.target.value);
    setPage(0);
  }, []);

  const onStatusSelectChange = useCallback((e: SelectChangeEvent) => {
    setStatusFilter(e.target.value);
    setPage(0);
  }, []);

  const onSearchTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearched(e.currentTarget.value);
  }, []);

  const onResetCompanyFilter = useCallback(() => {
    setCompanyFilter('');
    setPage(0);
  }, []);

  const onResetStatusFilter = useCallback(() => {
    setStatusFilter('');
    setPage(0);
  }, []);


  const onModalWindowClose = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const onModalWindowOpen = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const onSortChange = (e: React.MouseEvent<HTMLSpanElement>) => {
    const columnsWithChangedSortOrder = columns.map<Column>((item) => {
      if (item.id === e.currentTarget.id) {
        const newSortOrder = [
          ...sortOrder.filter((sortParam) => sortParam.id !== item.id),
          {
            id: item.id,
            sort: item.sortOrder === 'asc' ? 'desc' : 'asc',
          },
        ] as ISortParam[];
        setSortOrder(newSortOrder);

        return {
          ...item,
          sortOrder: item.sortOrder === 'asc' ? 'desc' : 'asc',
        };
      }
      return { ...item };
    });
    setColumns(columnsWithChangedSortOrder);
  };

  const onOutputCreate = () => {
    dispatch(
      getOutputsAction({
        search: searched,
        sort: sortParams,
        page,
        rowPerPage: rowsPerPage,
        companyId: companyFilter,
        status: statusFilter,
      })
    );
  };
  for (var i in outputs) {
    if (outputs[i].last_response) {
       if (outputs[i].last_response.length > 25) {
          outputs[i].last_response = `${outputs[i].last_response.slice(0, 25)}...`;
       }
    }
  }
  return (
    <Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
      {role.name !== Roles.USER && (
          <Button variant='contained' onClick={onModalWindowOpen} sx={{ alignSelf: 'flex-end' }}>
            Add output
            <AddIcon sx={{ marginLeft: '10px' }} />
          </Button>
        )}
        {isModalOpen && (
          <OutputCreateModal isOpen={isModalOpen} handleClose={onModalWindowClose} onFormSubmit={onOutputCreate} />
        )}
      </Grid>
      <Box sx={{ display: 'flex', width: '100%', alignItems: 'baseline', gap: '59px', marginBottom: '24px', }}>
        <Search onChange={onSearchTextChange} value={searched} cancelSearch={onCancelSearch} />
        <DropdownSelect
          value={companyFilter}
          values={companies}
          onChange={onCompanySelectChange}
          dataType='Customer'
          resetValue={onResetCompanyFilter}
          style={{
            maxWidth: '320px',
          }}
        />
        <DropdownSelect
          value={statusFilter}
          values={statuses}
          onChange={onStatusSelectChange}
          dataType='Status'
          resetValue={onResetStatusFilter}
          style={{
            maxWidth: '320px',
          }}
        />
      </Box>

      {isLoading ? (
        <Box display='flex' justifyContent='center' alignItems='center' marginTop={15}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {outputs?.length ? (
            <>
              <Table
                link='outputs'
                rows={outputs}
                columns={columns}
                page={page}
                rowsPerPage={rowsPerPage}
                onSorting={onSortChange}
                enableToSort
              />
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component='div'
                count={outputsAmount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Typography variant='h5' margin={2}>
              No Outputs to display
            </Typography>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default OutputsPage;
