import { ReactNode } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Modal,
  CircularProgress,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ICustomModalProps {
  title: string;
  isOpen: boolean;
  children: ReactNode;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

const CustomModal = ({
  title,
  isOpen,
  isLoading,
  children,
  isSubmitDisabled,
  handleClose,
  onSubmit,
}: ICustomModalProps) => {
  return (
    <Modal
      open={isOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'white',
          boxShadow: 24,
          p: 4,
          width: '60%',
          maxWidth: 400,
          padding: 3,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} id='modal-header'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <>
          {isLoading ? (
            <Box display='flex' justifyContent='center' alignItems='center' marginTop={2} marginBottom={2} height={320}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>{children}</Box>
          )}
        </>
        <Box
           sx={{
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'end',
             marginTop: 2,
             flexWrap: 'wrap',
           }}
         >
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{ marginRight: 2, marginTop: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={onSubmit}
            sx={{ marginTop: 2 }}
            disabled={isSubmitDisabled}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CustomModal;