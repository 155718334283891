import { Reducer } from 'redux';
import { IError, IRoleObject } from '../../../global/types/types';
import { GET_ROLES_LIST_FAILURE, GET_ROLES_LIST_SUCCESS, ROLES_LOADING } from '../../actions';
import { RolesActionsTypes } from '../../actions/rolesActionCreators/actionCreators';

interface IInitialRolesState {
  roles: IRoleObject[] | [];
  error: null | string | IError;
  isLoading: boolean;
}

const initialState: IInitialRolesState = {
  roles: [],
  error: null,
  isLoading: false,
};

const rolesReducer: Reducer<IInitialRolesState, RolesActionsTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_LIST_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        error: null,
      };
    case GET_ROLES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case ROLES_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};

export default rolesReducer;
