import axiosPrivate from '../api/axios';
import { AxiosResponse } from 'axios';

export const recoveryPassword = async (email: string): Promise<AxiosResponse<{ data: { token: string } }>> => {
  return axiosPrivate.post<{ data: { token: string } }>('/auth/password-recovery', { email });
};

export const resetPassword = async (password: string, token: string): Promise<AxiosResponse<{}>> => {
  return axiosPrivate.put(`/auth/password-recovery/${token}`, { password });
};

export const tokenVerification = async (token: string): Promise<AxiosResponse<{ data: { token: string } }>> => {
  return axiosPrivate.get<{ data: { token: string } }>(`/auth/password-recovery/${token}`);
};
