import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Avatar, Box, Container, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { emailSelector, userCompanySelector, userIdSelector } from '../../redux/selectors/authSelector';
import { logoutRequestAction } from '../../redux/actions/authActionCreators/actionCreators';
import { useNavigate } from 'react-router-dom';
import symbiusLogo from '../../images/mainpage-logo.png';
import userGuide from '../../user_guide.pdf';

const LayoutMenu = () => {
  const userEmail = useSelector(emailSelector);
  const userId = useSelector(userIdSelector);
  const company = useSelector(userCompanySelector);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onProfilePageShow = () => {
    navigate(`/users/${userId}`, { replace: true });
  };

  const onShowDocumentation = () => {
    window.open(userGuide);
  };

  return (
    <AppBar position='static'>
      <Container style={{ maxWidth: '100%' }}>
        <Toolbar disableGutters style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={userEmail}>
              <Box edge="start" onClick={handleOpenUserMenu} sx={{ p: 0, display: 'flex', cursor: 'pointer' }}>
                <Avatar
                  src={company?.logo ?? ''}
                  alt='User Icon'
                  sx={{ width: '30px', height: '30px', marginRight: '10px' }}
                />
                <Typography variant='h6' fontSize='16px' color='#fff'>
                  {company?.name}
                </Typography>
              </Box>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={onProfilePageShow}>
                <Typography textAlign='center'>
                  Profile
                </Typography>
              </MenuItem>

              <MenuItem onClick={onShowDocumentation}>
                <Typography textAlign='center'>
                  Documentation
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => { dispatch(logoutRequestAction()); }}>
                <Typography textAlign='center'>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box float="right" style={{justifyContent:"flex-end"}}>
            <img width="100" height="28" src={symbiusLogo}/>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default React.memo(LayoutMenu);
