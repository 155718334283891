import React from 'react';
import { TextField } from '@mui/material';

interface IDateTimePicker {
  label: string;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disablePreviousDate: boolean;
  defaultValue: string;
}

const DateTimePicker = ({ label, style, onChange, disablePreviousDate, defaultValue }: IDateTimePicker) => {
  return (
    <TextField
      id='datetime-local'
      label={label}
      type='datetime-local'
      InputLabelProps={{
        shrink: true,
      }}
      onChange={onChange}
      style={style}
      inputProps={{
        max: disablePreviousDate && new Date().toISOString().slice(0, 16),
      }}
      defaultValue={defaultValue}
    />
  );
};

export default React.memo(DateTimePicker);
