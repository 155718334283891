import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { getRoles } from '../../../services';
import { GET_ROLES_LIST } from '../../actions';
import { IRoleObject } from '../../../global/types/types';
import { rolesActions } from '../../actions/rolesActionCreators/actionCreators';

function* fetchRolesList() {
  try {
    yield put(rolesActions.setRolesLoading(true));

    const response: AxiosResponse<{ data: IRoleObject[] }> = yield call(getRoles);

    if (response.data.data && response.status === 200) {
      yield put(rolesActions.getRolesListSuccess(response.data.data));
    }
  } catch (error) {
    const e = error as AxiosError;
    yield put(
      rolesActions.getRolesListFailure({
        message: `${e?.message}${e?.response && e?.response.statusText && ': ' + e.response.statusText}`,
        code: Number(e?.code),
      })
    );
  } finally {
    yield put(rolesActions.setRolesLoading(false));
  }
}

export function* rolesSaga() {
  yield all([takeLatest(GET_ROLES_LIST, fetchRolesList)]);
}
