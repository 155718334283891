import { InferActionTypes } from '../../store/store';
import { IError, IRoleObject } from '../../../global/types/types';
import { GET_ROLES_LIST, GET_ROLES_LIST_FAILURE, GET_ROLES_LIST_SUCCESS, ROLES_LOADING } from './actions';

export const rolesActions = {
  getRolesList: () =>
    ({
      type: GET_ROLES_LIST,
    } as const),

  getRolesListSuccess: (roles: IRoleObject[]) =>
    ({
      type: GET_ROLES_LIST_SUCCESS,
      payload: roles,
    } as const),

  getRolesListFailure: (error: string | IError) =>
    ({
      type: GET_ROLES_LIST_FAILURE,
      payload: error,
    } as const),

  setRolesLoading: (isLoading: boolean) =>
    ({
      type: ROLES_LOADING,
      payload: isLoading,
    } as const),
};

export type RolesActionsTypes = InferActionTypes<typeof rolesActions>;
