import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Grid, TablePagination } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Table from '../../components/Table';
import { customersActions } from '../../redux/actions/customersActionCreators/actionCreators';
import {
  customersAmountSelector,
  customersSelector,
  customersTablePageSelector,
  isCustomersLoading,
} from '../../redux/selectors/customersSelector';
import CustomerCreateModal from '../../components/CustomerCreateModal';
import { availableDevicesSelector, roleSelector, userCompanySelector } from '../../redux/selectors/authSelector';
import { Roles } from '../../global/types/types';

export enum CustomerColumnFields {
  companyId = 'id',
  companyName = 'name',
  companyStatus = 'status',
  dateCreated = 'createdAt',
  numberOfDevices = 'devicesNumber',
}

export type CustomerTableColumns =
  | CustomerColumnFields.companyId
  | CustomerColumnFields.companyName
  | CustomerColumnFields.companyStatus
  | CustomerColumnFields.dateCreated
  | CustomerColumnFields.numberOfDevices;

export interface CustomerTableColumn {
  id: CustomerTableColumns;
  label: string;
  align?: 'right' | 'center' | 'left';
  style?: React.CSSProperties;
  format?: (value: number) => string;
}

const CustomersPage = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isCustomersLoading);
  const customers = useSelector(customersSelector);
  const availableDevices = useSelector(availableDevicesSelector);
  const customersAmount = useSelector(customersAmountSelector);
  const customersTablePage = useSelector(customersTablePageSelector);
  const role = useSelector(roleSelector);
  const userCompany = useSelector(userCompanySelector);

  const [page, setPage] = useState(customersTablePage);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    dispatch(customersActions.getCustomersList({
      isArray: true,
      page,
      rowPerPage
    }));
  }, [dispatch, page, rowPerPage]);

  const handleModal = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  }

  const onCustomerCreate = useCallback(() => {
    dispatch(customersActions.getCustomersList({
      isArray: true,
      page,
      rowPerPage
    }));
  }, [dispatch, page, rowPerPage]);

  const columns: CustomerTableColumn[] = [
    { id: CustomerColumnFields.companyId, label: 'Customer ID' },
    { id: CustomerColumnFields.companyName, label: 'Customer Name' },
    { id: CustomerColumnFields.companyStatus, label: 'Customer Status' },
    { id: CustomerColumnFields.dateCreated, label: 'Date created' },
    { id: CustomerColumnFields.numberOfDevices, label: 'Number of devices', style: { width: '130px' } },
  ];

  return (
    <Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        {role.name !== Roles.USER && (
          <Button variant='contained' onClick={handleModal} sx={{alignSelf: 'flex-end'}}>
            Add customer
            <AddIcon sx={{ marginLeft: '10px' }} />
          </Button>
        )}
        {
          isModalOpen && (
            <CustomerCreateModal
              open={isModalOpen}
              availableDevices={availableDevices}
              userCompany={userCompany}
              handleClose={handleModal}
              onFormSubmit={onCustomerCreate}
            />
          )
        }
      </Grid>
      <Grid
        container
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {isLoading && (
          <Box
            sx={{
              width: '100%',
              height: 'calc(100% - 80px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
            >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && !!customers.length && (
          <>
            <Table link={'customers'} rowsPerPage={rowPerPage} page={page} rows={customers} columns={columns} />
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component='div'
              count={customersAmount}
              rowsPerPage={rowPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomersPage;
