import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: AxiosError) => {
  return Promise.reject(error);
};

axiosPrivate.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        if ((window as Window).location.pathname !== '/login') {
          (window as Window).location = '/login';
        }
      } else {
        return Promise.reject(error.response.data);
      }
    }
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers!.authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => errorHandler(error)
);

export default axiosPrivate;
