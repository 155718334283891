import { IError } from '../../../global/types/types';
import { GET_OUTPUTS, GET_OUTPUTS_FAILURE, GET_OUTPUTS_SUCCESS, GET_OUTPUTS_LOADING } from './actions';
import { IOutput } from '../../../services/outputsService';
//import { outputStatuses } from '../../../global/constants/outputs';
import { OutputsTableColumns } from '../../../global/types/outputs-types';

export type TSortParams = 'asc' | 'desc';

//export type TStatusParams = keyof typeof outputStatuses | '';

interface IGetOutputsParams {
  search: string;
  sort: { id: OutputsTableColumns; sort: TSortParams }[];
  page: number;
  rowPerPage: number;
  companyId: string;
  status: string;
}

export interface IGetOutputsAction {
  type: typeof GET_OUTPUTS;
  payload: IGetOutputsParams;
}

export interface IGetOutputsSuccessAction {
  type: typeof GET_OUTPUTS_SUCCESS;
  payload: { outputs: IOutput[]; elementsAmount: number; page: number; pageAmount: number };
}

export interface IGetOutputsFailureAction {
  type: typeof GET_OUTPUTS_FAILURE;
  payload: string | IError;
}

export interface IGetOutputsLoadingAction {
  type: typeof GET_OUTPUTS_LOADING;
  payload: boolean;
}

export type TOutputsActionTypes =
  | IGetOutputsAction
  | IGetOutputsSuccessAction
  | IGetOutputsFailureAction
  | IGetOutputsLoadingAction;

export const getOutputsAction = (outputsParams: IGetOutputsParams) => {
  return {
    type: GET_OUTPUTS,
    payload: outputsParams,
  };
};

export const getOutputsSuccessAction = (params: {
  outputs: IOutput[];
  elementsAmount: number;
  page: number;
  pageAmount: number;
}) => {
  return {
    type: GET_OUTPUTS_SUCCESS,
    payload: params,
  };
};

export const getOutputsFailureAction = (error: string | IError) => {
  return {
    type: GET_OUTPUTS_FAILURE,
    payload: error,
  };
};

export const getOutputsLoading = (isLoading: boolean) => {
  return {
    type: GET_OUTPUTS_LOADING,
    payload: isLoading,
  };
};
