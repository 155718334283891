import React, { useState } from 'react';
import { Alert, TextField, Button, CircularProgress, Typography, Box, Container } from '@mui/material';
import { recoveryPassword } from '../../services/passwordService';
import { useForm } from '../../hooks';
import Notification from '../../components/Notification';

const ForgotPasswordPage = () => {
  const { error, isValid, value: email, changeHandler, submitHandler } = useForm('', ['isEmailValid']);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordRecoverySuccessful, setIsPasswordRecoverySuccessful] = useState(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await recoveryPassword(email);

      if (response.data && response.status === 200) {
        setIsPasswordRecoverySuccessful(true);
      }

      submitHandler();
    } catch (error: any) {
      setRequestError(error?.error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isPasswordRecoverySuccessful ? (
          <Notification title='The message has been send to your email' redirectButton />
        ) : (
          <>
            <Typography component='h1' variant='h5'>
              Forgot password
            </Typography>

            {isLoading ? (
              <Box sx={{ mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {requestError && <Alert severity='error'>{requestError}</Alert>}
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='none'
                  onChange={changeHandler}
                  value={email}
                  {...(error && { error: true, helperText: error })}
                />
                <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} disabled={!isValid}>
                  Forgot password
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
