import { Reducer } from 'redux';
import { ICompanyObject, IError } from '../../../global/types/types';
import { CUSTOMERS_LOADING, GET_CUSTOMER_LIST_FAILURE, GET_CUSTOMER_LIST_SUCCESS } from '../../actions';
import { CustomersActionsTypes } from '../../actions/customersActionCreators/actionCreators';

interface IInitialCustomersState {
  customers: ICompanyObject[] | [];
  page: number;
  pageAmount: number;
  elementsAmount: number;
  error: null | string | IError;
  isLoading: boolean;
}

const initialState: IInitialCustomersState = {
  customers: [],
  page: 0,
  pageAmount: 0,
  elementsAmount: 0,
  error: null,
  isLoading: false,
};

const customersReducer: Reducer<IInitialCustomersState, CustomersActionsTypes> = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        customers: action.payload.customers,
        page: action.payload.page,
        pageAmount: action.payload.pageAmount,
        elementsAmount: action.payload.elementsAmount,
        error: null,
      };
    case GET_CUSTOMER_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CUSTOMERS_LOADING:
      return { ...state, isLoading: action.payload };

    default:
      return state;
  }
};

export default customersReducer;
