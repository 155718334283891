import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface ITagsInputProps {
  value: string[];
  onChange: (args: string[]) => void;
  label: string;
}

const TagsInput = ({ value, onChange, label }: ITagsInputProps) => {
  const [inputTags, setInputTags] = useState<string[]>(value);

  return (
    <Autocomplete
      multiple
      freeSolo
      fullWidth
      id='tags-outlined'
      options={[]}
      value={inputTags}
      onChange={(event, newValue) => {
        setInputTags(newValue as string[]);
        onChange(newValue as string[]);
      }}
      renderInput={(params) => {
        return <TextField {...params} variant='outlined' label={label} placeholder={label} margin='normal' fullWidth />;
      }}
    />
  );
};

export default React.memo(TagsInput);
