import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from '../../hooks';
import { IEditedListItem } from '../../global/types/types';

interface IEditedListProps {
  isEdit?: boolean;
  fieldList?: IEditedListItem[];
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>[]>>;
  setFieldList: React.Dispatch<React.SetStateAction<IEditedListItem[]>>;
}

const NotEditableComponent = (item: IEditedListItem) => {
  if (item.info instanceof Array) {
    return (
      <Stack>
        <Typography variant='body1'>{item.title}</Typography>
        <Box>
          {item.info.length
            ? item.info.map((element) => (
                <Chip label={element} key={element} sx={{ marginRight: '5px', marginTop: '5px' }} />
              ))
            : '-'}
        </Box>
      </Stack>
    );
  } else if (item.type === 'select') {
    return <ListItemText primary={item.title} secondary={item.name ? item.name : '-'} />;
  } else {
    return <ListItemText primary={item.title} secondary={item.info ? item.info : '-'} />;
  }
};

export const EditedList = ({ isEdit = false, fieldList, setFieldList, setErrors }: IEditedListProps) => {
  const updateFieldList = useCallback(
    (fieldName: string, value: string, error: string | null) => {
      setFieldList((prevState) => {
        return prevState.map((item) => {
          if (item.fieldName !== fieldName) return item;
          value ? (item.info = value) : (item.info = null);
          return item;
        });
      });

      error
        ? setErrors((prevState) => [...prevState, { [fieldName]: error }])
        : setErrors((prevState) => {
            return prevState.filter((item) => {
              return Object.keys(item)[0] !== fieldName;
            });
          });
    },
    [setFieldList, setErrors]
  );

  return (
    <List>
      {fieldList &&
        fieldList.map((item) => {
          return (
            <ListItem key={item.title}>
              {isEdit && item.isEditable ? (
                <Field {...item} onChangeHandle={updateFieldList} />
              ) : (
                NotEditableComponent(item)
              )}
            </ListItem>
          );
        })}
    </List>
  );
};

interface FieldProps extends IEditedListItem {
  onChangeHandle: (fieldName: string, value: string, error: string | null) => any;
}

const Field = React.memo(
  ({ info, title, fieldName, onChangeHandle, Component = TextField, selectValues, required }: FieldProps) => {
    const isEmailField = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(title);

    const { changeHandler, value, isValid, error } = useForm(
      info as string,
      required ? [isEmailField ? 'isEmailValid' : 'isFieldRequired'] : []
    );

    const onValuesArrayChange = (tags: string) => {
      fieldName && onChangeHandle(fieldName, tags, error);
    };

    useEffect(() => {
      fieldName && onChangeHandle(fieldName, value, error);
    }, [value, fieldName, error, onChangeHandle]);

    const textFieldProps = {
      value: value ?? '',
      label: title,
      name: fieldName,
      error: !!error,
      helperText: error,
      onChange: changeHandler,
      required: required,
    };

    const selectProps = {
      ...textFieldProps,
      values: selectValues,
      dataType: title,
    };

    const tagsProps = {
      ...textFieldProps,
      onChange: onValuesArrayChange,
    };

    const propsType = () => {
      if (info instanceof Array) {
        return tagsProps;
      } else if (selectValues) {
        return selectProps;
      } else {
        return textFieldProps;
      }
    };

    const props = propsType();

    return (
      <FormControl error={!isValid && !!error} sx={{ width: '440px', marginBottom: '16px' }}>
        <Component {...props} />
        {required && selectValues && selectValues.length && (
          <FormHelperText sx={{ marginLeft: 0 }}>This field is required!</FormHelperText>
        )}
      </FormControl>
    );
  }
);
