import React, { useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import { routes } from '../../global/constants/routes';
import LayoutMenu from '../LayoutMenu';

const verticalTabProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const Layout = () => {
  const location = useLocation();
  const currentLocationTab = useMemo(
    () => routes.reduce((acc, item, index) => (location.pathname.includes(item.path) ? acc + index : acc), 0),
    [location.pathname]
  );

  return (
    <>
      <LayoutMenu />
      <Box sx={{ bgcolor: 'background.paper', display: 'flex' }}>
        <Box sx={{ width: '20%', minWidth: '150px', minHeight: '100%' }}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={currentLocationTab}
            aria-label='Vertical tabs menu'
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              height: '100%',
              paddingTop: '16px',
              '& a:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            }}
          >
            {routes.map(({ label, path, sidebar }, index) => {
              if (sidebar) {
                return (
                  <Tab
                    key={label}
                    label={label}
                    component={Link}
                    style={{
                      padding: '24px',
                      alignItems: 'flex-start',
                    }}
                    {...verticalTabProps(index)}
                    to={path}
                  />
                );
              } else {
                return null;
              }
            })}
            ;
          </Tabs>
        </Box>

        <Box
          sx={{
            width: '80%',
            padding: '16px',
            height: 'calc(100vh - 112px)',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Layout;
