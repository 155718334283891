import { Column } from '../../components/Table';
import { convertFunction } from '../../utils';

export const usersColumns: Column[] = [
  { id: 'firstName', label: 'First Name', align: 'center', sortOrder: 'asc' },
  { id: 'lastName', label: 'Last Name', align: 'center', sortOrder: 'asc' },
  { id: 'email', label: 'E-mail', align: 'center', sortOrder: 'asc' },
  { id: 'status', label: 'Status', align: 'center', sortOrder: 'asc' },
  {
    id: 'loginAt',
    label: 'Last login',
    format: (value: number) => value.toLocaleString('en-US'),
    align: 'center',
    sortOrder: 'asc',
  },
];

export const usersStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  UNREGISTERED: 'Unregistered',
};

export const userStatuses = convertFunction(usersStatuses);
