import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Alert, Box, Grid, Skeleton } from '@mui/material';
import { EditedList } from '../../components/EditedList';
import { getSingleCustomer, updateSingleCustomer } from '../../services';
import DropdownSelect from '../../components/Select';
import { convertFunction, updatedSingleCustomer } from '../../utils';
import { roleSelector, userCompanySelector } from '../../redux/selectors/authSelector';
import { ICompanyObject, IEditedListItem, Roles } from '../../global/types/types';
import UploadLogo from '../../components/UploadLogo';
import DevicesAmountForm from '../../components/DevicesAmountForm';
import EditCard from '../../components/EditCard';
import BackButton from '../../components/BackButton';

export const companyStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const SingleCustomerPage = () => {
  const { id } = useParams();
  const role = useSelector(roleSelector);
  const userCompany = useSelector(userCompanySelector);

  const [requestError, setRequestError] = useState('');
  const [customer, setCustomer] = useState<ICompanyObject | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logo, setLogo] = useState<string | null>(null);
  const [devicesLimit, setDevicesLimit] = useState<number | null>(0);
  const [unlimitedDevices, setUnlimitedDevices] = useState<boolean>(false);
  const [fieldList, setFieldList] = useState<IEditedListItem[]>([]);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  const statuses = useMemo(() => convertFunction(companyStatuses), []);
  const isUsersCompany = useMemo(() => userCompany?.id === Number(id), [userCompany, id]);

  const convertToValidData = useCallback(
    (customerData: ICompanyObject) => {
      return [
        {
          title: 'Customer Name',
          info: customerData?.name,
          fieldName: 'name',
          isEditable: true,
          required: true,
        },
        {
          title: 'Customer Status',
          info: customerData?.status,
          fieldName: 'status',
          Component: DropdownSelect,
          selectValues: statuses,
          isEditable: true,
        },
      ];
    },
    [statuses]
  );

  useEffect(() => {
    const fetchSingleUser = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleCustomer(`${id}`);

        if (response && response.status === 200) {
          setCustomer(response.data.data);

          response.data.data.deviceLimit && setDevicesLimit(response.data.data.deviceLimit);

          if (!response.data.data.deviceLimit && !response.data.data.availableDeviceLimit) {
            setUnlimitedDevices(true);
            setDevicesLimit(null);
          } else {
            setUnlimitedDevices(false);
          }

          if (response.data.data.logo) {
            setLogo(response.data.data.logo);
          }

          setFieldList(convertToValidData(response.data.data));
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleUser();
  }, [id, convertToValidData]);

  const resetForm = useCallback(() => {
    if (customer) {
      setLogo(customer.logo);
      setFieldList(convertToValidData(customer));
      setDevicesLimit(customer.deviceLimit);

      if (customer.deviceLimit) {
        setUnlimitedDevices(false);
      } else {
        setUnlimitedDevices(true);
      }
    }
  }, [convertToValidData, customer]);

  const toggleEditModeHandle = useCallback(() => {
    setIsEdit((prevState) => !prevState);

    if (isEdit) {
      resetForm();
    }
  }, [isEdit, resetForm]);

  const submitHandle = useCallback(async () => {
    try {
      const updatedData = updatedSingleCustomer(fieldList);

      const response = await updateSingleCustomer(id as string, {
        name: updatedData.name as string,
        status: updatedData.status as string,
        deviceLimit: devicesLimit,
        logo: logoFile,
      });

      if (response && response.status === 200) {
        setCustomer(response.data.data);
      }
    } catch (error: any) {
      setRequestError(error?.error.message);
    } finally {
      setIsEdit(false);
    }
  }, [devicesLimit, fieldList, id, logoFile]);

  const changeLogoHandle = (file: File | null) => {
    if (file) {
      setLogoFile(file);
      setLogo(URL.createObjectURL(file));
    } else {
      setLogoFile(null);
      setLogo(null);
    }
  };

  const onLimitChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | null) => {
      if (e) {
        const value = e.target.value;
        setDevicesLimit(e.target.value === null ? null : Number(e.target.value));

        if (customer?.availableDeviceLimit && Number(value) >= customer?.availableDeviceLimit) {
          setDevicesLimit(customer?.availableDeviceLimit);
        }
      } else {
        setDevicesLimit(customer?.deviceLimit || null);
      }
    },
    [customer?.availableDeviceLimit, customer?.deviceLimit]
  );

  const onUnlimitedChange = (e: React.ChangeEvent<HTMLInputElement> | null) => {
    if (e) {
      setDevicesLimit(e.target.checked ? null : 0);
      setUnlimitedDevices(e.target.checked);
    } else {
      setUnlimitedDevices(false);
    }
  };

  const resetRequestError = () => {
    setRequestError('');
  };

  return (
    <>
      <BackButton to='/customers' style={{ marginBottom: '20px' }} />
      <Grid container>
        {isLoading ? (
          <Skeleton variant='rectangular' width={490} height={482} sx={{ borderRadius: '4px' }} />
        ) : (
          <>
            {customer && (
              <Grid container>
                <EditCard
                  isEdit={isEdit}
                  isEditable={role.name !== Roles.USER}
                  errors={errors}
                  isDisabled={devicesLimit === 0}
                  toggleEditModeHandle={toggleEditModeHandle}
                  onSubmitHandle={submitHandle}
                >
                  {requestError && (
                    <Alert
                      severity='error'
                      onClose={resetRequestError}
                      sx={{
                        marginTop: '16px',
                        alignSelf: 'center',
                        width: '408px',
                      }}
                    >
                      {requestError}
                    </Alert>
                  )}

                  <UploadLogo isEditVisible={isEdit} logoUrl={logo} onLogoChange={changeLogoHandle} />

                  <EditedList fieldList={fieldList} isEdit={isEdit} setFieldList={setFieldList} setErrors={setErrors} />

                  <Box sx={{ paddingLeft: '16px', paddingRight: '19px', marginBottom: '24px' }}>
                    <DevicesAmountForm
                      isDisable={isUsersCompany}
                      isUnlimitedDevices={unlimitedDevices}
                      availableDevices={customer.availableDeviceLimit}
                      deviceLimit={devicesLimit}
                      isEditVisible={isEdit}
                      onLimitChange={onLimitChange}
                      onUnlimitedChange={onUnlimitedChange}
                    />
                  </Box>
                </EditCard>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default SingleCustomerPage;
