import { all, fork } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { usersSaga } from './usersSaga';
import { customersSaga } from './customersSaga';
import { rolesSaga } from './rolesSaga';
import { devicesSaga } from './devicesSaga';
import { outputsSaga } from './outputsSaga';

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(usersSaga),
    fork(customersSaga),
    fork(rolesSaga),
    fork(devicesSaga),
    fork(outputsSaga),
  ]);
}
