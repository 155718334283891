import React, { useState } from 'react';
import { FormControl, FormGroup, FormLabel, Grid, Switch, Typography } from '@mui/material';

interface IToggleComponentProps {
  onToggle: (arg: boolean) => void;
  truthyValue: string;
  falsyValue: string;
  title: string;
  style?: React.CSSProperties;
  required?: boolean;
}

const ToggleComponent = ({ onToggle, truthyValue, falsyValue, title, style, required }: IToggleComponentProps) => {
  const [isToggleSwitched, setIsToggleSwitched] = useState(false);

  const onToggleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsToggleSwitched(e.target.checked);
    onToggle(e.target.checked);
  };
  return (
    <FormControl style={style}>
      <FormLabel
        required={required}
        focused={false}
        sx={{
          span: {
            color: 'red',
          },
        }}
      >
        {title}
      </FormLabel>
      <FormGroup>
        <Grid component='label' container alignItems='center'>
          <Typography variant='body2'>{falsyValue}</Typography>
          <Switch checked={isToggleSwitched} onChange={onToggleSwitch} value='checked' />
          <Typography variant='body2'>{truthyValue}</Typography>
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

export default React.memo(ToggleComponent);
