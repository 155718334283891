import React from 'react';
import { IconButton, TextField, Tooltip, Box } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface ISearchProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  cancelSearch: () => void;
}

const Search = ({ onChange, value, cancelSearch }: ISearchProps) => {
  return (
    <Box style={{ display: 'flex', maxWidth: '375px', width: '100%' }}>
      <TextField
        id='search-bar'
        className='text'
        fullWidth
        onChange={onChange}
        label='Enter value'
        variant='outlined'
        placeholder='Search...'
        size='small'
        value={value}
      />

      <Tooltip title='Clean search area and reset filter'>
        <IconButton aria-label='reset' onClick={cancelSearch}>
          <CancelOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default React.memo(Search);
