import { IError } from '../../../global/types/types';
import { GET_USERS, GET_USERS_FAILURE, GET_USERS_SUCCESS, GET_USERS_LOADING } from './actions';
import { IUser } from '../../../services/userService';
import { usersStatuses } from '../../../global/constants/users';
import { UsersTableColumns } from '../../../global/types/users-types';

export type TSortParams = 'asc' | 'desc';

export type TStatusParams = keyof typeof usersStatuses | '';

interface IGetUsersParams {
  search: string;
  sort: { id: UsersTableColumns; sort: TSortParams }[];
  page: number;
  rowPerPage: number;
  companyId: string;
  status: TStatusParams;
}

export interface IGetUsersAction {
  type: typeof GET_USERS;
  payload: IGetUsersParams;
}

export interface IGetUsersSuccessAction {
  type: typeof GET_USERS_SUCCESS;
  payload: { users: IUser[]; elementsAmount: number; page: number; pageAmount: number };
}

export interface IGetUsersFailureAction {
  type: typeof GET_USERS_FAILURE;
  payload: string | IError;
}

export interface IGetUsersLoadingAction {
  type: typeof GET_USERS_LOADING;
  payload: boolean;
}

export type TUsersActionTypes =
  | IGetUsersAction
  | IGetUsersSuccessAction
  | IGetUsersFailureAction
  | IGetUsersLoadingAction;

export const getUsersAction = (usersParams: IGetUsersParams) => {
  return {
    type: GET_USERS,
    payload: usersParams,
  };
};

export const getUsersSuccessAction = (params: {
  users: IUser[];
  elementsAmount: number;
  page: number;
  pageAmount: number;
}) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: params,
  };
};

export const getUsersFailureAction = (error: string | IError) => {
  return {
    type: GET_USERS_FAILURE,
    payload: error,
  };
};

export const getUsersLoading = (isLoading: boolean) => {
  return {
    type: GET_USERS_LOADING,
    payload: isLoading,
  };
};
