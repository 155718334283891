import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table as StickHeadTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Typography,
  Tooltip,
} from '@mui/material';
import { TSortParams } from '../../redux/actions/usersActionCreators/actionCreators';
import { IDeviceCountUnit } from '../../services/widgetService';
import { TDevicesCountTableColumns, TDevicesInfoTableColumns } from '../../pages/DashboardPage';

export type Columns = TDevicesCountTableColumns | TDevicesInfoTableColumns;

export interface IDeviceInfoUnit {
  id: string;
  title: string;
  data: number | string;
}

export type Row = IDeviceCountUnit | IDeviceInfoUnit;

export type Rows = (Row & {
  style?: React.CSSProperties;
  link?: { pathname: string; search: string } | null;
  tooltipLabel?: string;
})[];

export interface Column {
  id: Columns;
  label: string;
  style?: React.CSSProperties;
  align?: 'right' | 'center' | 'left';
  format?: (value: number) => string;
  sortOrder?: TSortParams;
}

interface DeviceCountFooterItem {
  id: number;
  companyName: number;
  value: number;
}

export type TFooter = DeviceCountFooterItem[];

interface IDataTableProps {
  rows: Rows;
  columns: Column[];
  footerData?: TFooter;
  tableHeight?: string;
  title?: string;
  isHeaderViewed?: boolean;
  style?: React.CSSProperties;
}

const styles: Record<string, React.CSSProperties> = {
  layoutStyles: { position: 'relative', display: 'block', width: '100%' },
  bodyStyles: { display: 'block', position: 'relative', width: '100%', overflowY: 'auto' },
  rowStyles: { width: '100%', display: 'flex' },
  cellStyles: { flexBasis: '100%', flexGrow: '2', display: 'block' },
};

const ScrollableDataTable = ({
  rows,
  columns,
  footerData,
  tableHeight,
  title,
  isHeaderViewed = false,
  style,
}: IDataTableProps) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (link?: { pathname: string; search: string } | null) => {
      link && navigate(link);
    },
    [navigate]
  );

  return (
    <>
      <Paper sx={{ maxWidth: '500px', overflow: 'hidden', ...style }}>
        {title && (
          <Typography variant='h6' align='left' margin={2}>
            {title}
          </Typography>
        )}
        <TableContainer>
          <StickHeadTable
            stickyHeader
            aria-label='table'
            sx={{
              display: 'block',
            }}
          >
            {isHeaderViewed && (
              <TableHead sx={styles.layoutStyles}>
                <TableRow sx={styles.rowStyles}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        ...column.style,
                        ...styles.cellStyles,
                        fontWeight: 'bold',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody
              sx={{
                ...styles.bodyStyles,
                maxHeight: tableHeight,
              }}
            >
              {rows.map((row) => {
                return (
                  <TableRow
                    hover={!!row?.link}
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => handleRowClick(row?.link)}
                    sx={{ ...styles.rowStyles, ...row?.style, '&:hover': { cursor: row?.link && 'pointer' } }}
                  >
                    {columns.map((column) => {
                      // @ts-ignore
                      const value = row[column.id as keyof Row];

                      return (
                        <Tooltip title={row?.tooltipLabel ?? ''} arrow key={column.id + row.id + value}>
                          <TableCell align={column.align} sx={styles.cellStyles}>
                            {value}
                          </TableCell>
                        </Tooltip>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            {footerData && footerData.length && (
              <TableFooter
                sx={{
                  ...styles.layoutStyles,
                  background: 'rgb(25, 118, 210)',
                }}
              >
                {footerData.map((row) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id} style={styles.rowStyles}>
                      {columns.map((column) => {
                        // @ts-ignore
                        const value = row?.[column.id];

                        return (
                          <TableCell
                            key={column.id + row.id}
                            align={column.align}
                            sx={{ ...styles.cellStyles, color: '#fff', fontWeight: 'bold' }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableFooter>
            )}
          </StickHeadTable>
        </TableContainer>
      </Paper>
    </>
  );
};

export default React.memo(ScrollableDataTable);
