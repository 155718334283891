import React, { useCallback, useMemo, useState } from 'react';
import CustomModal from '../CustomModal';
import { Alert, FormControl, SelectChangeEvent, TextField } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { devicesTypesSelector } from '../../redux/selectors/devicesSelector';
import DropdownSelect from '../Select';
import ToggleComponent from '../ToggleComponent';
import { createDevice, ICreateDeviceFields } from '../../services/devicesService';
import TagsInput from '../TagsInput';
import RequirementHint from '../RequirementHint';
import { customersSelector } from '../../redux/selectors/customersSelector';
import { userCompanySelector } from '../../redux/selectors/authSelector';
import { devicesActions } from '../../redux/actions/devicesActionCreators/actionCreators';
import { isDevicesMessageSelector } from '../../redux/selectors/devicesSelector';

enum Statuses {
  'Inactive' = 'INACTIVE',
  'Active' = 'ACTIVE',
}

const MODAL_TITLE = 'Create device';

interface IDeviceCreateModalProps {
  isOpen: boolean;
  onFormSubmit: () => void;
  handleClose: () => void;
}

const DeviceCreateModal = ({ isOpen, handleClose, onFormSubmit }: IDeviceCreateModalProps) => {
  const dispatch = useDispatch();
  const userCompany = useSelector(userCompanySelector);
  const isMessage = useSelector(isDevicesMessageSelector);

  const initialCreateDeviceState = useMemo(
    () => ({
      name: '',
      serial: '',
      description: '',
      status: Statuses.Inactive,
      company: {
        id: userCompany?.id.toString() ?? '',
      },
      type: {
        id: '',
      },
      tagNames: [],
    }),
    [userCompany?.id]
  );

  const [fields, setFields] = useState<ICreateDeviceFields>(initialCreateDeviceState);

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const fetchedCompanies = useSelector(customersSelector);
  const fetchedDevicesTypes = useSelector(devicesTypesSelector);

  const isBtnDisabled = useMemo(
    () => !fields.name || !fields.company.id || !fields.type.id || !fields.serial || !fields.status || isLoading,
    [fields.name, fields.company.id, fields.type.id, fields.serial, fields.status, isLoading]
  );

  const companies = useMemo(
    () =>
      fetchedCompanies
        .map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [fetchedCompanies]
  );
  const types = useMemo(
    () => fetchedDevicesTypes.map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() })),
    [fetchedDevicesTypes]
  );

  const onChangeCompanySelect = useCallback((e: SelectChangeEvent) => {
    setFields((prevState) => ({ ...prevState, company: { id: e.target.value } }));
  }, []);

  const onChangeTypeSelect = useCallback((e: SelectChangeEvent) => {
    setFields((prevState) => ({ ...prevState, type: { id: e.target.value } }));
  }, []);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFields((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  }, []);

  const onChangeTags = (tags: string[]) => {
    setFields((prevState) => ({ ...prevState, tagNames: tags }));
  };

  const onToggleSwitch = (isChecked: boolean) => {
    setFields((prevState) => ({ ...prevState, status: isChecked ? Statuses.Active : Statuses.Inactive }));
  };

  const onModalWindowClose = useCallback(() => {
    handleClose();
    setFields(initialCreateDeviceState);
  }, [handleClose, initialCreateDeviceState]);

  const onSubmit = useCallback(() => {
    const createSingleDevice = async () => {
      try {
        setIsLoading(true);
        fields.serial = fields.serial.toLowerCase();
        const response = await createDevice(fields);

        if (response.data.data && response.status === 200) {
            setRequestError(null);
            onFormSubmit();
            onModalWindowClose();
            // put code here to check response for an mqtt password and show to user?
            if (response.data.data.devicePassword) {
                //alert(`This device needs a username and password to send data to Symbius.\n\nUsername: ${response.data.data.serial}\n\nPassword: ${response.data.data.devicePassword}\n\nCopy and paste these now as you wont be able to see them again.`)
                dispatch(devicesActions.setDevicesMessage(`The last device added needs a username and password to send data to Symbius.\n\nUsername: "${response.data.data.serial}"\n\nPassword: "${response.data.data.devicePassword}"\n\nCopy and paste these now as you wont be able to see them again. Then close this alert.`))
            }
        }
      } catch (error: any) {
        setRequestError(error?.error.message);
      } finally {
        setIsLoading(false);
      }
    };

    createSingleDevice();
  }, [fields, onFormSubmit, onModalWindowClose]);

  return (
    <CustomModal
      title={MODAL_TITLE}
      isLoading={isLoading}
      isOpen={isOpen}
      isSubmitDisabled={isBtnDisabled}
      handleClose={handleClose}
      onSubmit={onSubmit}
    >
      <FormControl
        component='form'
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {requestError && <Alert severity='error'>{requestError}</Alert>}

        <DropdownSelect
          value={fields.company.id ?? ''}
          values={companies}
          onChange={onChangeCompanySelect}
          dataType='Customer Name'
          required
        />

        <DropdownSelect
          value={fields.type.id ?? ''}
          values={types}
          onChange={onChangeTypeSelect}
          dataType='Device Type'
          required
        />

        <TextField
          required
          fullWidth
          name='name'
          label='Device Name'
          type='text'
          id='name'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onInputChange}
          value={fields.name}
        />

        <TextField
          fullWidth
          name='description'
          label='Device Description'
          type='text'
          id='description'
          autoComplete='none'
          variant='standard'
          margin='dense'
          onChange={onInputChange}
          value={fields.description}
        />

        <TextField
          required
          fullWidth
          name='serial'
          label='Device SN'
          type='text'
          id='serial'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onInputChange}
          value={fields.serial}
        />

        <ToggleComponent
          onToggle={onToggleSwitch}
          truthyValue='Active'
          falsyValue='Inactive'
          title='Status'
          style={{
            marginTop: '20px',
          }}
        />

        <TagsInput value={fields.tagNames} onChange={onChangeTags} label='Tags' />

        <RequirementHint />
      </FormControl>
    </CustomModal>
  );
};

export default React.memo(DeviceCreateModal);
