export const deviceStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};


export const toggleFilter = {
  true: 'Yes',
  false: 'No',
};
