import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table as StickHeadTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { UsersTableColumns } from '../../global/types/users-types';
import { OutputsTableColumns } from '../../global/types/outputs-types';
import { IUser } from '../../services/userService';
import { viewingInTable } from '../../utils';
import { TSortParams } from '../../redux/actions/usersActionCreators/actionCreators';
import { ICompanyObject } from '../../global/types/types';
import { IDevice, ISingleDeviceData } from '../../services/devicesService';
import { TDevicesTableColumns } from '../../global/types/devices-types';
import { IOutput } from '../../services/outputsService';
import { CustomerTableColumns } from '../../pages/CustomersPage';
import { TDeviceDataColumns } from '../DeviceData';
import { IDeviceCountUnit } from '../../services/widgetService';

export type Columns = UsersTableColumns | CustomerTableColumns | TDevicesTableColumns | TDeviceDataColumns | OutputsTableColumns;
export type Row = (IUser | ICompanyObject | ISingleDeviceData | IDevice | IDeviceCountUnit | IOutput) & {
  style?: React.CSSProperties;
};
export type Rows = Row[];

export interface Column {
  id: Columns;
  label: string;
  minWidth?: number | string;
  align?: 'right' | 'center' | 'left';
  format?: (value: number) => string;
  sortOrder?: TSortParams;
}

interface ITableProps {
  rows: Rows;
  columns: Column[];
  page?: number;
  rowsPerPage?: number;
  link?: string;
  onSorting?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  enableToSort?: boolean;
}

const Table = ({ rows, columns, link, onSorting, enableToSort }: ITableProps) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (id: string | number) => {
      navigate(`/${link}/${id}`);
    },
    [link, navigate]
  );

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <StickHeadTable stickyHeader aria-label='table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                  >
                    {enableToSort ? (
                      <TableSortLabel id={column.id} active direction={column.sortOrder} onClick={onSorting}>
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '&:hover': { cursor: 'pointer' },
              }}
            >
              {rows.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id} onClick={() => handleRowClick(row.id)}>
                    {columns.map((column) => {
                      const value = viewingInTable(row, column);

                      return (
                        <TableCell key={column.id + row.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </StickHeadTable>
        </TableContainer>
      </Paper>
    </>
  );
};

export default React.memo(Table);
