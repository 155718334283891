import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { GET_USERS } from '../../actions';
import { getUsers } from '../../../services';
import {
  getUsersAction,
  getUsersFailureAction,
  getUsersLoading,
  getUsersSuccessAction,
} from '../../actions/usersActionCreators/actionCreators';

function* fetchUsers({
  payload: { search, sort, page, rowPerPage, companyId, status },
}: ReturnType<typeof getUsersAction>) {
  try {
    yield put(getUsersLoading(true));
    const response: AxiosResponse = yield call(getUsers, search, sort, page, rowPerPage, companyId, status);

    if (response.data.data && response.status === 200) {
      yield put(
        getUsersSuccessAction({
          users: response.data.data,
          elementsAmount: response.data.elementsAmount,
          page: response.data.page,
          pageAmount: response.data.pageAmount,
        })
      );
    }
  } catch (error) {
    const e = error as AxiosError;

    yield put(
      getUsersFailureAction({
        message: `${e?.message}${e?.response && e?.response.statusText && ': ' + e.response.statusText}`,
        code: Number(e?.code),
      })
    );
  } finally {
    yield put(getUsersLoading(false));
  }
}

export function* usersSaga() {
  yield all([takeLatest(GET_USERS, fetchUsers)]);
}
