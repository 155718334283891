// LOGIN
export const LOGIN = 'LOGIN' as const;
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS' as const;
export const LOGIN_FAILURE = 'LOGIN_FAILURE' as const;

// LOGOUT
export const LOGOUT = 'LOGOUT' as const;
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS' as const;

// LOADING
export const AUTH_LOADING = 'AUTH_LOADING' as const;
