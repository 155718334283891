import { IError } from '../../../global/types/types';
import { InferActionTypes } from '../../store/store';
import { DEVICES_MESSAGE, DEVICES_LOADING, GET_DEVICES_LIST, GET_DEVICES_LIST_FAILURE, GET_DEVICES_LIST_SUCCESS } from './actions';
import { IDevice } from '../../../services/devicesService';
import { TDevicesTableColumns } from '../../../global/types/devices-types';

export type TSortParams = 'asc' | 'desc';

interface IGetDevicesParams {
  search: string;
  status: string;
  company: string;
  sort: { id: TDevicesTableColumns; sort: TSortParams }[];
  type: string;
  page: number;
  rowPerPage: number;
  neverSentData: string;
  tag: string;
  noDataInLast24hr?: string;
}

export const devicesActions = {
  getDevicesList: (devicesParams: IGetDevicesParams) =>
    ({
      type: GET_DEVICES_LIST,
      payload: devicesParams,
    } as const),

  getDevicesListSuccess: (params: { devices: IDevice[]; elementsAmount: number; page: number; pageAmount: number }) =>
    ({
      type: GET_DEVICES_LIST_SUCCESS,
      payload: params,
    } as const),

  getDevicesListFailure: (error: string | IError) =>
    ({
      type: GET_DEVICES_LIST_FAILURE,
      payload: error,
    } as const),

  setDevicesLoading: (isLoading: boolean) =>
    ({
      type: DEVICES_LOADING,
      payload: isLoading,
    } as const),

  setDevicesMessage: (isMessage: string) =>
    ({
      type: DEVICES_MESSAGE,
      payload: isMessage,
    } as const)
};

export type TDevicesActionsTypes = InferActionTypes<typeof devicesActions>;
