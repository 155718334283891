import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface ISelectElement {
  name: string;
  value: string;
}

interface IFormSelectProps {
  required: boolean;
  label: string;
  value?: string;
  values: ISelectElement[];
  onChange: (e: SelectChangeEvent) => void;
}

const FormSelect = ({ required, label, value = '', onChange, values }: IFormSelectProps) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onOptionChange = (e: SelectChangeEvent) => {
    onChange(e);
    setSelectedValue(e.target.value);
  };

  return (
    <FormControl margin='dense' variant='standard' required={required} fullWidth>
      <InputLabel id='form-select'>{label}</InputLabel>
      <Select
        labelId='form-select'
        id='form-select'
        value={selectedValue}
        onChange={onOptionChange}
        label={label}
        defaultValue=''
        MenuProps={{ sx: { height: 240 } }}
      >
        {values.map(({ value, name }) => {
          return (
            <MenuItem value={value} key={value}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default React.memo(FormSelect);
