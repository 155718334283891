// location
export interface ILocation {
  state: {
    from: Location;
  };
}

// requests

// error (common)
export interface IError {
  code: number;
  message: string;
}

// auth
export const enum Roles {
  SUPERADMIN = 'Superadmin',
  ADMIN = 'Admin',
  USER = 'User',
}

export interface IUserInputData {
  email: string;
  password: string;
}

export interface ILoginUserData extends IDataObject {
  user: IDataObject
}

export interface ICompanyObject {
  deviceLimit: number | null;
  id: number;
  name: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  numberOfDevices?: number;
  availableDeviceLimit?: number | null;
  devicesNumber: number | null;
  logo: string | null;
}

export interface IRoleObject {
  id: number;
  name: Roles;
  slug: Roles;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDataObject {
  id: string;
  email: string;
  password: string;
  firstName: string | null;
  lastName: string | null;
  company: ICompanyObject;
  role: IRoleObject;
  status: string;
  token: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAuthResponse {
  data: IDataObject;
  message: string;
  statusCode: number;
  successful: boolean;
}

export interface IEditedListItem {
  type?: string;
  name?: string | null;
  isEditable: boolean;
  title: string;
  info: string | number | null | string[];
  fieldName?: string;
  Component?: any;
  selectValues?: { [key: string]: string }[];
  required?: boolean;
}
