import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LOGIN, LOGOUT } from '../../actions';
import { loginRequest } from '../../../services';
import {
  authLoading,
  loginRequestAction,
  loginRequestFailure,
  loginRequestSuccess,
  logoutRequestSuccess,
} from '../../actions/authActionCreators/actionCreators';
import { AxiosResponse } from 'axios';
import { IAuthResponse } from '../../../global/types/types';

function* fetchLogin({ payload: { email, password } }: ReturnType<typeof loginRequestAction>) {
  try {
    yield put(authLoading(true));
    const response: AxiosResponse<IAuthResponse> = yield call(loginRequest, email, password);

    if (response.data && response.status === 200) {
      const token = response.data.data.token;
      localStorage.setItem('token', token);

      yield put(loginRequestSuccess(response.data.data));
    }
  } catch (e: any) {
    yield put(
      loginRequestFailure({
        message: e?.response?.data?.error?.message ?? e?.error?.message,
        code: Number(e?.response?.data?.error?.statusCode),
      })
    );
  } finally {
    yield put(authLoading(false));
  }
}

function* logout() {
  localStorage.clear();
  yield put(logoutRequestSuccess());
}

export function* authSaga() {
  yield all([takeLatest(LOGIN, fetchLogin), takeLatest(LOGOUT, logout)]);
}
