import { IError } from '../../../global/types/types';
import { InferActionTypes } from '../../store/store';
import { GET_DEVICES_TAGS, GET_DEVICES_TAGS_SUCCESS, GET_DEVICES_TAGS_FAILURE, DEVICES_TAGS_LOADING } from './actions';
import { IDeviceTag } from '../../../services/devicesService';

export const devicesTagsActions = {
  getDevicesTags: () =>
    ({
      type: GET_DEVICES_TAGS,
    } as const),

  getDevicesTagsSuccess: (devicesTags: { allTags: IDeviceTag[]; ownTags: IDeviceTag[] }) =>
    ({
      type: GET_DEVICES_TAGS_SUCCESS,
      payload: devicesTags,
    } as const),

  getDevicesTagsFailure: (error: string | IError) =>
    ({
      type: GET_DEVICES_TAGS_FAILURE,
      payload: error,
    } as const),

  setDevicesTagsLoading: (isLoading: boolean) =>
    ({
      type: DEVICES_TAGS_LOADING,
      payload: isLoading,
    } as const),
};

export type TDevicesTagsActionsTypes = InferActionTypes<typeof devicesTagsActions>;
