import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, TextField, Button, CircularProgress, Typography, Box, Container } from '@mui/material';
import { IError, ILocation } from '../../global/types/types';
import { loginRequestAction } from '../../redux/actions/authActionCreators/actionCreators';
import { authErrorSelector, isAuthLoadingSelector, isAuthSelector } from '../../redux/selectors/authSelector';
import alliotLogo from '../../images/symbius-logo.png';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });
  const [isValid, setIsValid] = useState(false);

  const isLoading = useSelector(isAuthLoadingSelector);
  const isAuth = useSelector(isAuthSelector);
  const error = useSelector(authErrorSelector);

  const navigate = useNavigate();
  const location = useLocation();
  const from = useMemo(() => (location as ILocation)?.state?.from?.pathname ?? '/dashboard', [location]);

  useEffect(() => {
    if (isAuth) {
      navigate(from, { replace: true });
    }
  }, [isAuth, navigate, from]);

  useLayoutEffect(() => {
    error && setPassword('');
  }, [error]);

  const dispatch = useDispatch();

  const handleSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(loginRequestAction({ email, password }));
  };

  const validateEmailInputValue = (value: string) => {
    if (value) {
      const isValidEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value);
      if (isValidEmail) {
        setErrors((state) => ({ ...state, email: '' }));
        setIsValid(true);
      } else {
        setErrors((state) => ({ ...state, email: 'Email is not valid.' }));
        setIsValid(false);
      }
    } else {
      setErrors((state) => ({ ...state, email: 'This field is required.' }));
      setIsValid(false);
    }
  };

  const validatePasswordInputValue = (value: string) => {
    if (value) {
      setErrors((state) => ({ ...state, password: '' }));
      setIsValid(true);
    } else {
      setErrors((state) => ({ ...state, password: 'This field is required.' }));
      setIsValid(false);
    }
  };

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    validateEmailInputValue(e.currentTarget.value);
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    validatePasswordInputValue(e.currentTarget.value);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
	<img src={alliotLogo}/>
        <Typography component='h1' variant='h5' align="center">
          Making your IoT useable
        </Typography>
        {isLoading || isAuth ? (
          <Box sx={{ mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} textAlign='end'>
            {error && <Alert severity='error'>{(error as IError).message}</Alert>}
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='none'
              onChange={changeEmail}
              value={email}
              {...(errors['email'] && { error: true, helperText: errors['email'] })}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='none'
              onChange={changePassword}
              value={password}
              {...(errors['password'] && { error: true, helperText: errors['password'] })}
            />
            <Typography component={Link} to='/password-recovery' style={{ textDecoration: 'none' }} align='right'>
              Forgot password?
            </Typography>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
              disabled={!isValid || !email || !password}
            >
              Sign in
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default LoginPage;
