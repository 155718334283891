import React, { useCallback, useMemo, useState } from 'react';
import CustomModal from '../CustomModal';
import { Alert, FormControl, SelectChangeEvent, TextField } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils';
import { useSelector } from 'react-redux';
import DropdownSelect from '../Select';
import ToggleComponent from '../ToggleComponent';
import { createOutput, ICreateOutputFields } from '../../services/outputsService';
import TagsInput from '../TagsInput';
import RequirementHint from '../RequirementHint';
import { customersSelector } from '../../redux/selectors/customersSelector';
import { userCompanySelector } from '../../redux/selectors/authSelector';
import { convertFunction } from '../../utils';
import validator from 'validator';

enum Types {
  'HTTP' = 'HTTP'
}

enum Statuses {
  'Inactive' = 'INACTIVE',
  'Active' = 'ACTIVE',
}

const MODAL_TITLE = 'Create output';

interface IOutputCreateModalProps {
  isOpen: boolean;
  onFormSubmit: () => void;
  handleClose: () => void;
}

const OutputCreateModal = ({ isOpen, handleClose, onFormSubmit }: IOutputCreateModalProps) => {
  const userCompany = useSelector(userCompanySelector);

  const initialCreateOutputState = useMemo(
    () => ({
      name: '',
      url: '',
      header_name: '',
      header_value: '',
      status: Statuses.Inactive,
      company: {
        id: userCompany?.id.toString() ?? '',
      },
      type: Types.HTTP,
      tagNames: [],
    }),
    [userCompany?.id]
  );

  const [fields, setFields] = useState<ICreateOutputFields>(initialCreateOutputState);

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState(null);

  const fetchedCompanies = useSelector(customersSelector);

  const isBtnDisabled = useMemo(
    () => !fields.name || !fields.company.id || !fields.type || !fields.url || isLoading,
    [fields.name, fields.company.id, fields.type, fields.url, isLoading]
  );

  const companies = useMemo(
    () =>
      fetchedCompanies
        .map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [fetchedCompanies]
  );

  const types = useMemo(() => convertFunction(Types), []);


  const onChangeCompanySelect = useCallback((e: SelectChangeEvent) => {
    setFields((prevState) => ({ ...prevState, company: { id: e.target.value } }));
  }, []);

  const onChangeTypeSelect = useCallback((e: SelectChangeEvent) => {
    setFields((prevState) => ({ ...prevState, type: e.target.value }));
  }, []);

  const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFields((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  }, []);

  const onChangeTags = (tags: string[]) => {
    setFields((prevState) => ({ ...prevState, tagNames: tags }));
  };

  const onToggleSwitch = (isChecked: boolean) => {
    setFields((prevState) => ({ ...prevState, status: isChecked ? Statuses.Active : Statuses.Inactive }));
  };

  const onModalWindowClose = useCallback(() => {
    handleClose();
    setFields(initialCreateOutputState);
  }, [handleClose, initialCreateOutputState]);

  const onSubmit = useCallback(() => {
    const createSingleOutput = async () => {
      try {
        setIsLoading(true);
        const theUrl = fields.url ? fields.url.toString(): "";
        if (!validator.isURL(theUrl)) { 
            throw  new Error('Please enter a valid URL'); 
        }
        const theHeaderName = fields.header_name ? fields.header_name.toString(): "";
        if (theHeaderName != "") {
            if (!/^[0-9a-zA-Z_-]+$/g.test(theHeaderName)) {
                throw  new Error('Invalid HTTP Header name. Allowed characters are a-z, A-Z, _, -');
            }
        }
    
        const response = await createOutput({
          name: fields.name,
          status: fields.status,
          type: fields.type,
          settings: {"url": fields.url ? fields.url.toString() : "", "header_name": fields.header_name ? fields.header_name.toString() : "", "header_value": fields.header_value ? fields.header_value.toString() : ""},
          company: {
            id: fields.company.id,
          }
        }
        );

        if (response.data.data && response.status === 200) {
          setRequestError(null);
          onFormSubmit();
          onModalWindowClose();
        }
      } catch (error: any) {
        if ('error' in error) {
            setRequestError(error?.error.message);
        } else {
            setRequestError(error?.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    createSingleOutput();
  }, [fields, onFormSubmit, onModalWindowClose]);

  return (
    <CustomModal
      title={MODAL_TITLE}
      isLoading={isLoading}
      isOpen={isOpen}
      isSubmitDisabled={isBtnDisabled}
      handleClose={handleClose}
      onSubmit={onSubmit}
    >
      <FormControl
        component='form'
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {requestError && <Alert severity='error'>{requestError}</Alert>}

        <DropdownSelect
          value={fields.company.id ?? ''}
          values={companies}
          onChange={onChangeCompanySelect}
          dataType='Customer Name'
          required
        />

        <DropdownSelect
          value={fields.type}
          values={types}
          onChange={onChangeTypeSelect}
          dataType='Output Type'
          required
        />

        <TextField
          required
          fullWidth
          name='name'
          label='Output Name'
          type='text'
          id='name'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onInputChange}
          value={fields.name}
        />

        <TextField
          required
          fullWidth
          name='url'
          label='URL'
          type='text'
          id='url'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onInputChange}
          value={fields.url}
        />

        <TextField
          fullWidth
          name='header_name'
          label='HTTP Header Name'
          type='text'
          id='header_name'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onInputChange}
          value={fields.header_name}
        />

        <TextField
          fullWidth
          name='header_value'
          label='HTTP Header Value'
          type='text'
          id='header_value'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onInputChange}
          value={fields.header_value}
        />

        <ToggleComponent
          onToggle={onToggleSwitch}
          truthyValue='Active'
          falsyValue='Inactive'
          title='Status'
          style={{
            marginTop: '20px',
          }}
        />

        <TagsInput value={fields.tagNames} onChange={onChangeTags} label='Tags' />

        <RequirementHint />
      </FormControl>
    </CustomModal>
  );
};

export default React.memo(OutputCreateModal);
