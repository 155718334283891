import { Column, Row } from '../components/Table';
import { IEditedListItem } from '../global/types/types';
import { UsersTableColumns } from '../global/types/users-types';
import { OutputsTableColumns } from '../global/types/outputs-types';
import { TDevicesTableColumns } from '../global/types/devices-types';
import { TSortParams } from '../redux/actions/usersActionCreators/actionCreators';

export const viewingInTable = (row: Row, column: Column) => {
  const key: any = row[column.id as keyof Row];
  if (key == 0) {
    return '0';
  } else if (key == 1) {
    return '1';      
  }
  if (key) {
    if (key instanceof Array) {
      return key.length ? key.map((item) => item.name).join(', ') : '-';
    } else {
      return ['loginAt', 'createdAt', 'lastRequestAt', 'datetime'].includes(column.id)
        ? new Date(key).toLocaleString()
        : key.toString();
    }
  } else {
    return '-';
  }
};

export const conditionalInfoViewing = (data: string) => {
  return data ? data : '-';
};

export const convertFunction = (obj: Record<string, string>) => {
  return Object.entries(obj).reduce<{ name: string; value: string }[]>((acc, [value, key]) => {
    return [...acc, { name: key, value }];
  }, []);
};

export const convertDataToSortQueue = (array: { id: UsersTableColumns; sort: TSortParams }[]): string => {
  return array.reduce((acc, item) => {
    return acc + `sort=${item.sort}:${item.id}&`;
  }, '');
};

export const convertOutputDataToSortQueue = (array: { id: OutputsTableColumns; sort: TSortParams }[]): string => {
  return array.reduce((acc, item) => {
    return acc + `sort=${item.sort}:${item.id}&`;
  }, '');
};

export const convertDeviceDataToSortQueue = (array: { id: TDevicesTableColumns; sort: TSortParams }[]): string => {
  return array.reduce((acc, item) => {
    return acc + `sort=${item.sort}:${item.id}&`;
  }, '');
};

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
};


export const updatedSingleCustomer = (list: IEditedListItem[]) => {
  return list.reduce(
    (acc: Record<string, string | number | null>, { fieldName, info, isEditable }) => {
      //@ts-ignore
      if (fieldName && isEditable) acc[fieldName] = info;
      return acc;
    },
    {}
)};
