import React from "react";
import { Alert, Box, Checkbox, FormControlLabel, ListItemText, TextField } from "@mui/material";

interface IDevicesAmountFormProps {
  availableDevices?: number | null;
  deviceLimit?: number | null;
  isEditVisible?: boolean;
  isUnlimitedDevices?: boolean;
  isDisable?: boolean;
  onLimitChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUnlimitedChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DevicesAmountForm = ({
  availableDevices,
  deviceLimit,
  isUnlimitedDevices,
  isDisable = false,
  isEditVisible = true,
  onLimitChange,
  onUnlimitedChange,
}: IDevicesAmountFormProps) => {
  return (
    <Box sx={{width: '100%'}}>
      <Alert
          severity={availableDevices !== null ? 'warning' : 'info'}
        >
          {`Available amount of devices: ${availableDevices !== null ? availableDevices : 'Unlimited'}`}
        </Alert>

        <FormControlLabel
          labelPlacement="start"
          disabled={!!availableDevices || !isEditVisible || isDisable}
          control={
            <Checkbox onChange={onUnlimitedChange} checked={isUnlimitedDevices} />
          }
          label="Unlimited amount of devices:"
          sx={{
            marginTop: '8px',
            alignSelf: 'start',
            marginLeft: '0px',
          }}
        />

        {isEditVisible ? <TextField
          required
          fullWidth
          name='deviceLimit'
          label='Device limit'
          type='number'
          id='deviceLimit'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputProps={{
            inputProps: { min: 0, max: availableDevices ? availableDevices : null }
          }}
          InputLabelProps={{
            sx: {
              'span': {
                color: 'red'
              }
            }
          }}
          onChange={onLimitChange}
          disabled={isUnlimitedDevices || isDisable}
          value={deviceLimit === null ? '' : deviceLimit}
        /> : (
          <ListItemText
            primary='Device limit'
            secondary={deviceLimit !== null ? deviceLimit : 'Unlimited'}
          />
        )}
    </Box>
  )
}

export default React.memo(DevicesAmountForm);