import { CUSTOMERS_LOADING, GET_CUSTOMER_LIST, GET_CUSTOMER_LIST_FAILURE, GET_CUSTOMER_LIST_SUCCESS } from './actions';
import { InferActionTypes } from '../../store/store';
import { ICompanyObject, IError } from '../../../global/types/types';

interface IGetCustomersParams {
  isArray: boolean;
  page?: number;
  rowPerPage?: number;
}

export const customersActions = {
  getCustomersList: (customersParams: IGetCustomersParams) =>
    ({
      type: GET_CUSTOMER_LIST,
      payload: customersParams
    } as const),

  getCustomersListSuccess: (params: { customers: ICompanyObject[]; elementsAmount: number; page: number; pageAmount: number }) =>
    ({
      type: GET_CUSTOMER_LIST_SUCCESS,
      payload: params,
    } as const),

  getCustomersListFailure: (error: string | IError) =>
    ({
      type: GET_CUSTOMER_LIST_FAILURE,
      payload: error,
    } as const),

  setCustomersLoading: (isLoading: boolean) =>
    ({
      type: CUSTOMERS_LOADING,
      payload: isLoading,
    } as const),
};

export type CustomersActionsTypes = InferActionTypes<typeof customersActions>;
