import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IPasswordNotificationProps {
  title: string;
  redirectButton?: boolean;
}

const Notification = ({ title, redirectButton }: IPasswordNotificationProps) => {
  const navigate = useNavigate();

  const onLoginPageRedirect = () => {
    navigate('/login', { replace: true });
  };

  return (
    <Box>
      <Typography variant='h6' align='center'>
        {title}
      </Typography>
      {redirectButton && (
        <Button type='button' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} onClick={onLoginPageRedirect}>
          Go to login page
        </Button>
      )}
    </Box>
  );
};

export default React.memo(Notification);
