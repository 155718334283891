import React from 'react';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface ISelectElement {
  name: string;
  value: string;
}

interface IDropdownSelectProps {
  value: string;
  values: ISelectElement[];
  dataType: string;
  style?: React.CSSProperties;
  onChange: (e: SelectChangeEvent) => void;
  resetValue?: () => void;
  required?: boolean;
}

const DropdownSelect = ({
  values,
  onChange,
  dataType,
  value = '',
  resetValue,
  style,
  required,
}: IDropdownSelectProps) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        ...style,
      }}
    >
      <FormControl variant='standard' size='small' style={{ width: '100%' }}>
        <InputLabel
          required={required}
          id='select-label'
          sx={{
            span: {
              color: 'red',
            },
          }}
        >
          {dataType}
        </InputLabel>
        <Select
          labelId='select-label'
          id='select'
          onChange={onChange}
          value={value}
          defaultValue=''
          MenuProps={{ sx: { height: 240 } }}
        >
          {values.map(({ value, name }) => (
            <MenuItem value={value} key={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {resetValue && (
        <Tooltip title='Reset filter'>
          <IconButton aria-label='reset' onClick={resetValue}>
            <CancelOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default React.memo(DropdownSelect);
