import React, { ReactNode } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';

interface IEditCard {
  isEdit: boolean;
  isEditable: boolean;
  children: ReactNode;
  errors: Record<string, string>[];
  isDisabled?: boolean;
  onSubmitHandle: () => void;
  toggleEditModeHandle?: () => void;
}

const EditCard = ({
  isEdit = false,
  isEditable,
  children,
  errors,
  isDisabled,
  onSubmitHandle,
  toggleEditModeHandle,
}: IEditCard) => (
  <Card
    sx={{
      minWidth: 475,
      display: 'flex',
      flexDirection: 'column',
      padding: '16px 8px 32px 8px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 20px 32px 16px',
      }}
    >
      {!isEdit && isEditable && (
        <Button
          sx={{
            width: '40px',
            alignSelf: 'flex-end',
          }}
          onClick={toggleEditModeHandle}
          disabled={isEdit}
          variant={'contained'}
        >
          Edit
        </Button>
      )}
    </Box>

    {children}

    {isEditable && (
      <Grid
        container
        gap={2}
        sx={{
          justifyContent: isEdit ? 'end' : 'start',
        }}
      >
        {isEdit && (
          <Box sx={{ marginRight: '20px' }}>
            <Button
              sx={{
                marginRight: '16px',
              }}
              onClick={toggleEditModeHandle}
              disabled={!isEdit}
              variant={'outlined'}
            >
              Cancel
            </Button>

            <Button onClick={onSubmitHandle} disabled={!isEdit || !!errors.length || isDisabled} variant='contained'>
              Save
            </Button>
          </Box>
        )}
      </Grid>
    )}
  </Card>
);

export default EditCard;
