import React, { useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';

interface IUploadLogoProps {
  logoUrl?: string | null;
  isEditVisible?: boolean;
  onLogoChange: (file: File | null) => void;
}

const UploadLogo = ({
  logoUrl,
  isEditVisible = true,
  onLogoChange
}: IUploadLogoProps) => {
  const logoRef = useRef(null);

  const onFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files) {
      const checkedSize = Math.floor(e.target.files[0].size / 1024 / 1024) > 2;
      !checkedSize && onLogoChange(e.target.files[0]);
    }
  };

  const onFileClear = () => {
    onLogoChange(null);
    if (logoRef.current) {
      // @ts-ignore
      logoRef.current.value = '';
    }
  };

  const onClickLabel = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    !isEditVisible && e.preventDefault()
  };

  return (
    <Box sx={{
      position: 'relative',
      marginBottom: '20px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
      }}>
      <label
        htmlFor='contained-button-file'
        onClick={onClickLabel}
        style={{
          width: '160px',
          height: '160px',
          marginBottom: '20px',
          borderRadius: '50%',
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#eaeaea',
            background: logoUrl ? `url(${logoUrl})` : '',
            cursor: 'pointer',
            marginTop: '16px',
            marginBottom: '6px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: 160,
            height: 160,

            '&:hover': {
              svg: {
                display: 'block',
                color: '#9e9e9e'
              }
            },
            svg: {
              display: logoUrl ? 'none' : 'block'
            }
          }}
        >
         {isEditVisible && <AddAPhotoOutlinedIcon sx={{
             width: 48,
             height: 48,
            color: '#b1b1b1'
          }} />}
          <input
            ref={logoRef}
            onChange={onFileUpload}
            hidden
            accept="image/jpg,image/png,image/jpeg"
            id='contained-button-file'
            multiple
            type='file'
          />
        </Box>
      </label>

      {isEditVisible && (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
          <Box sx={{alignSelf: 'end', color: '#a6a6a6',}}>
            <Typography sx={{fontSize: '10px'}}>Max size: 2 MB</Typography>
            <Typography sx={{fontSize: '10px'}}>Formats: jpeg, jpg, png</Typography>
          </Box>

          <Button disabled={!logoUrl} onClick={onFileClear} size='small'>
            Clear
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default UploadLogo;