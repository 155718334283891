import { IError } from '../../../global/types/types';
import { InferActionTypes } from '../../store/store';
import {
  DEVICES_TYPES_LOADING,
  GET_DEVICES_TYPES,
  GET_DEVICES_TYPES_FAILURE,
  GET_DEVICES_TYPES_SUCCESS,
} from './actions';
import { IDeviceType } from '../../../services/devicesService';

export const devicesTypesActions = {
  getDevicesTypes: () =>
    ({
      type: GET_DEVICES_TYPES,
    } as const),

  getDevicesTypesSuccess: (devicesTypes: IDeviceType[]) =>
    ({
      type: GET_DEVICES_TYPES_SUCCESS,
      payload: devicesTypes,
    } as const),

  getDevicesTypesFailure: (error: string | IError) =>
    ({
      type: GET_DEVICES_TYPES_FAILURE,
      payload: error,
    } as const),

  setDevicesTypesLoading: (isLoading: boolean) =>
    ({
      type: DEVICES_TYPES_LOADING,
      payload: isLoading,
    } as const),
};

export type TDevicesTypesActionsTypes = InferActionTypes<typeof devicesTypesActions>;
