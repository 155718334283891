import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Grid, Skeleton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { EditedList } from '../EditedList';
import { editSingleDevice, getSingleDevice, IDevice, newDevicePassword } from '../../services/devicesService';
import { capitalizeFirstLetter, convertFunction, updatedSingleCustomer } from '../../utils';
import TagsInput from '../TagsInput';
import { useParams } from 'react-router-dom';
import EditCard from '../EditCard';
import { IEditedListItem } from '../../global/types/types';
import DropdownSelect from '../Select';
import { deviceStatuses } from '../../global/constants/devices';
import { useSelector, useDispatch } from 'react-redux';
import { customersSelector } from '../../redux/selectors/customersSelector';
import { devicesActions } from '../../redux/actions/devicesActionCreators/actionCreators';
import {
  isDevicesMessageSelector,
} from '../../redux/selectors/devicesSelector';


const DeviceDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const fetchedCompanies = useSelector(customersSelector);

  const [device, setDevice] = useState<IDevice | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const isMessage = useSelector(isDevicesMessageSelector);
  const [requestError, setRequestError] = useState('');
  const [fieldList, setFieldList] = useState<IEditedListItem[]>([]);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  const statuses = useMemo(() => convertFunction(deviceStatuses), []);
  const companies = useMemo(
    () =>
      fetchedCompanies
        .map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [fetchedCompanies]
  );

  const convertToValidData = useCallback(
    (deviceData: IDevice) => {
      return [
        {
          title: 'Customer Name',
          info: deviceData?.company?.id,
          name: deviceData?.company?.name,
          fieldName: 'company',
          Component: DropdownSelect,
          selectValues: companies,
          isEditable: true,
          type: 'select',
        },
        {
          title: 'Device Type',
          info: capitalizeFirstLetter(deviceData?.type?.name),
          fieldName: 'deviceType',
          isEditable: false,
        },
        {
          title: 'Device Name',
          info: deviceData?.name,
          fieldName: 'name',
          isEditable: true,
        },
        {
          title: 'Device Description',
          info: deviceData?.description,
          fieldName: 'description',
          isEditable: true,
          required: false,
        },
        {
          title: 'Device SN',
          info: deviceData?.serial,
          fieldName: 'serial',
          isEditable: false,
        },
        {
          title: 'Device Status',
          info: deviceData?.status,
          Component: DropdownSelect,
          fieldName: 'status',
          selectValues: statuses,
          isEditable: true,
        },
        {
          Component: TagsInput,
          title: 'Device Tags',
          info: deviceData?.tags.map((item) => item.name),
          fieldName: 'tagNames',
          isEditable: true,
          required: false,
        },
        {
          title: 'Last Date and Time Data Up Sent',
          info: deviceData?.lastRequestAt ? new Date(deviceData?.lastRequestAt).toLocaleString() : null,
          fieldName: 'loginAt',
          isEditable: false,
        },
        {
          title: 'Time Next Data Expected (only eFento devices send this)',
          info: deviceData?.nextRequestAt ? new Date(deviceData?.nextRequestAt).toLocaleString() : null,
          fieldName: 'nextRequestAt',
          isEditable: false,
        },

      ];
    },
    [statuses, companies]
  );

  useEffect(() => {
    const fetchSingleDeviceDetails = async () => {
      try {
        setIsLoading(true);

        const response = await getSingleDevice(id as string);

        if (response && response.status === 200) {
          setDevice(response.data.data);
          setFieldList(convertToValidData(response.data.data));
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleDeviceDetails();
  }, [convertToValidData, id]);

  const toggleEditModeHandle = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const submitHandle = useCallback(async () => {
    try {
      setIsLoading(true);

      const updatedData = updatedSingleCustomer(fieldList);

      const response = await editSingleDevice(device?.id as string, updatedData);

      if (response.data.data && response.status === 200) {
        setDevice(response.data.data);

        setRequestError('');
      }
    } catch (e: any) {
      setRequestError(e.error.message);
    } finally {
      setIsLoading(false);
      toggleEditModeHandle();
    }
  }, [device?.id, fieldList, toggleEditModeHandle]);

  const list = useMemo(() => {
    return convertToValidData(device as IDevice);
  }, [convertToValidData, device]);


  const newPasswordHandle = useCallback(async () => {
    handleClickOpen()
  }, [device?.serial]); 


  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleClickOpen = () => {
    setConfirmOpen(true);
  };

  const handleCloseOK = async () => {
    setConfirmOpen(false);
    const response = await newDevicePassword(device?.serial as string);
    dispatch(devicesActions.setDevicesMessage(`New password generated. This device will no longer work until this new password is configured.\n\nUsername: "${device?.serial}"\n\nPassword: "${response.data.data}"\n\nCopy and paste these now as you wont be able to see them again. Then close this alert.`))
  };

  const handleCloseCancel = () => {
    setConfirmOpen(false);
  };

  return (
    <>
      <Grid container>
        {isLoading ? (
          <Skeleton variant='rectangular' width={490} height={568} sx={{ borderRadius: '4px' }} />
        ) : (
          <>
            {isMessage != '' && (
              <Grid container justifyContent='flex-start' alignItems='center' marginTop={2}>
                <Alert onClose={() => (dispatch(devicesActions.setDevicesMessage('')))} sx={{ whiteSpace: 'pre-line' }} severity='warning'><strong>Message:</strong> {isMessage}</Alert>
              </Grid>
            )}
            {requestError && (
              <Grid container justifyContent='flex-start' alignItems='center' marginTop={2}>
                <Alert severity='error'>{requestError}. Device was not edited</Alert>
              </Grid>
            )}


            <Dialog
              open={confirmOpen}
              onClose={handleCloseCancel}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                  {"Generate new device password?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" sx={{ whiteSpace: 'pre-line' }}>
                  This will overwrite the previous device password causing the device to stop working until it is re-configured.<br/><br/>Are you sure?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseCancel} autoFocus>Cancel</Button>
                  <Button onClick={handleCloseOK}>OK</Button>
                </DialogActions>
            </Dialog>
            


            <Grid justifyContent='flex-start' alignItems='center'>
            <EditCard
              isEdit={isEdit}
              isEditable={device?.isEditable || false}
              errors={errors}
              onSubmitHandle={submitHandle}
              toggleEditModeHandle={toggleEditModeHandle}
            >
              <EditedList fieldList={list} isEdit={isEdit} setErrors={setErrors} setFieldList={setFieldList} />
            </EditCard>
            {device?.type.code && ['adeunis', 'advantech'].includes(device?.type.code) && (
                <Button 
                sx={{ width: '300px', margin: '16px 20px 32px 16px' }}//alignSelf: 'flex-end',}}
                onClick={newPasswordHandle}
                variant={'contained'}
                >
                New Device Password
                </Button>
            )}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default React.memo(DeviceDetails);
