import { AxiosResponse } from 'axios';
import axiosPrivate from '../api/axios';

export interface IDeviceCountUnit {
  id: string | number;
  companyName: string | number;
  value: number;
  isParentCompany?: boolean;
}

interface IDeviceCount {
  inDetail: IDeviceCountUnit[];
  childrenAmount: number;
  ownAmount: number;
  totalAmount: number;
  customerCompaniesAmount: number;
}

interface IDeviceCountResponse {
  data: IDeviceCount;
}

export const getCountDevices = async (): Promise<AxiosResponse<IDeviceCountResponse>> => {
  return axiosPrivate.get<IDeviceCountResponse>(`/widget/count-devices`);
};

export const getCountNewDevices = async (): Promise<AxiosResponse<IDeviceCountResponse>> => {
  return axiosPrivate.get<IDeviceCountResponse>(`/widget/count-new`);
};

export const getCountDisconnectedDevices = async (): Promise<AxiosResponse<IDeviceCountResponse>> => {
  return axiosPrivate.get<IDeviceCountResponse>(`/widget/count-disconected-devices`);
};

export const getCountMessagesFromDevices = async (): Promise<AxiosResponse<{ data: number }>> => {
  return axiosPrivate.get<{ data: number }>(`/widget/count-data/`);
};
