import React, { useState } from 'react';
import { useForm } from '../../hooks';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import { register } from '../../services/authService';
import Notification from '../../components/Notification';

const CreatePasswordPage = () => {
  const { id } = useParams();

  const { error, isValid, value: password, changeHandler, submitHandler } = useForm('', ['isPasswordFieldValid']);

  const [requestError, setRequestError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserRegisterSuccessful, setIsUserRegisterSuccessful] = useState(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await register(id as string, password);

      if (response.data.data && response.status === 200) {
        setIsUserRegisterSuccessful(true);
      }

      submitHandler();
    } catch (error: any) {
      setRequestError(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isUserRegisterSuccessful ? (
          <Notification title='The password has been set successfully' redirectButton />
        ) : (
          <>
            <Typography component='h1' variant='h5'>
              Create password
            </Typography>

            {isLoading ? (
              <Box sx={{ mt: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {requestError && <Alert severity='error'>{requestError}</Alert>}
                <TextField
                  type='password'
                  margin='normal'
                  required
                  fullWidth
                  id='password'
                  label='Password'
                  name='password'
                  autoComplete='none'
                  onChange={changeHandler}
                  value={password}
                  {...(error && { error: true, helperText: error })}
                />
                <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }} disabled={!isValid}>
                  Create password
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default CreatePasswordPage;
