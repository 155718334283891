import { combineReducers } from 'redux';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import customersReducer from './customersReducer';
import rolesReducer from './rolesReducer';
import devicesReducer from './devicesReducer';
import outputsReducer from './outputsReducer';
import { LOGOUT } from '../actions';

export const appReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  customers: customersReducer,
  roles: rolesReducer,
  devices: devicesReducer,
  outputs: outputsReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
