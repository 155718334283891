import React, { useCallback, useEffect, useState } from 'react';
import DateTimePicker from '../DateTimePicker';
import { Alert, Box, Card, Grid, Skeleton, TablePagination, Typography } from '@mui/material';
import { getSingleDeviceData } from '../../services/devicesService';
import { useParams } from 'react-router-dom';
import Table from '../Table';

export enum DeviceDataColumnFields {
  deviceDateTime = 'datetime',
  deviceField = 'field',
  deviceValue = 'value',
}

export type TDeviceDataColumns =
  | DeviceDataColumnFields.deviceDateTime
  | DeviceDataColumnFields.deviceField
  | DeviceDataColumnFields.deviceValue;

export interface IDeviceDataTableColumn {
  id: TDeviceDataColumns;
  label: string;
  align?: 'right' | 'center' | 'left';
  style?: React.CSSProperties;
  format?: (value: number) => string;
}

const DeviceData = () => {
  const { id } = useParams();

  const [deviceData, setDeviceData] = useState<null | any>(null);
  const [page, setPage] = useState(0);
  const [devicesAmount, setDevicesAmount] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);

  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const [dateFrom, setDateFrom] = useState<Date | string>(new Date(new Date().getTime() - 86400000).toISOString());
  const [dateTo, setDateTo] = useState<Date | string>('');

  const defaultFromDatetime = new Date(new Date().getTime() - 86400000).toISOString().slice(0, -5);

  useEffect(() => {
    const fetchSingleDeviceData = async () => {
      try {
        setIsLoading(true);

        const response = await getSingleDeviceData(id as string, rowPerPage, page, dateFrom, dateTo);

        setDeviceData(response.data.data);
        setPage(response.data.page);
        setDevicesAmount(response.data.elementsAmount);
      } catch (err: any) {
        setRequestError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleDeviceData();
  }, [id, rowPerPage, page, dateFrom, dateTo]);

  const onDateFromChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDateFrom(new Date(e.target.value).toISOString());
  }, []);

  const onDateToChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDateTo(new Date(e.target.value).toISOString());
  }, []);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowPerPage(+event.target.value);
    setPage(0);
  };

  const columns: IDeviceDataTableColumn[] = [
    {
      id: DeviceDataColumnFields.deviceDateTime,
      label: 'Date and Time',
      align: 'center',
    },
    { id: DeviceDataColumnFields.deviceField, label: 'Field', align: 'center' },
    { id: DeviceDataColumnFields.deviceValue, label: 'Value', align: 'center' },
  ];

  return (
    <Grid container>
      <>
        {requestError && (
          <Grid container justifyContent='flex-start' alignItems='center' marginTop={2}>
            <Alert severity='error'>{requestError}</Alert>
          </Grid>
        )}
        <Card
          sx={{
            minWidth: 475,
            minHeight: 232,
            position: 'relative',
            padding: '16px 8px 32px 8px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <DateTimePicker
              label='Date and Time From'
              style={{ marginTop: '20px' }}
              onChange={onDateFromChange}
              disablePreviousDate
              defaultValue={defaultFromDatetime}
            />
            <DateTimePicker
              label='Date and Time To'
              style={{ marginTop: '20px' }}
              onChange={onDateToChange}
              disablePreviousDate
              defaultValue=''
            />
          </Box>

          {isLoading ? (
            <Skeleton variant='rectangular' width={490} height={568} sx={{ borderRadius: '4px' }} />
          ) : (
            <>
              {deviceData?.length ? (
                <>
                  <Table rows={deviceData} columns={columns} page={page} rowsPerPage={rowPerPage} />
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component='div'
                    count={devicesAmount}
                    rowsPerPage={rowPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <Typography variant='h5' margin={2}>
                  No data to display
                </Typography>
              )}
            </>
          )}
        </Card>
      </>
    </Grid>
  );
};

export default React.memo(DeviceData);
