import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  FormControl,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import CustomModal from '../CustomModal';
import UploadLogo from '../UploadLogo';
import { createCustomer, getCustomers, getSingleCustomer } from '../../services/customersServices';
import FormSelect from '../FormSelect';
import DevicesAmountForm from '../DevicesAmountForm';
import { capitalizeFirstLetter } from '../../utils';
import { ICompanyObject } from '../../global/types/types';

const MODAL_TITLE = 'Create customer';

interface ICreateCustomerInitialState {
  name: string;
  deviceLimit: number | null,
  logo: File | null;
  parent: string,
}

const initialCreateCustomerState: ICreateCustomerInitialState = {
  name: '',
  deviceLimit: 0,
  logo: null,
  parent: '',
};

interface UserCreateModalProps {
  open: boolean;
  availableDevices: number | null,
  userCompany: ICompanyObject | null,
  onFormSubmit: () => void;
  handleClose: () => void;
}

const CustomerCreateModal = ({
  open,
  availableDevices,
  userCompany,
  handleClose,
  onFormSubmit,
}: UserCreateModalProps) => {
  const [customers, setCustomers] = useState<ICompanyObject[]>([]);
  const [customerData, setCustomerData] = useState<ICreateCustomerInitialState>(initialCreateCustomerState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestError, setRequestError] = useState('');
  const [availableDevicesAmount, setAvailableDevicesAmount] = useState<number | null>(availableDevices);
  const [unlimitedDevices, setUnlimitedDevices] = useState<boolean>(false);

  const isDisabledBtn = ((customerData.deviceLimit !== null) && (Number(customerData.deviceLimit) === 0))
    || !customerData.name
    || isLoading;

  const convertedCustomers = useMemo(
    () => customers.map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() })),
    [customers]
  );

  const logoUrl = useMemo(() => {
    return customerData.logo ? URL.createObjectURL(customerData.logo) : null
  }, [customerData.logo]);

  useEffect(() => {
    (async function() {
      const response = await getCustomers(true);

      if (response && response.status === 200) {
        setCustomers(response.data.data);
      }
    })();
  }, []);

  useEffect(() => {
    if (userCompany?.id) {
      setCustomerData({ ...customerData, parent: userCompany?.id.toString()});
    }
    // eslint-disable-next-line
  }, [userCompany]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, name: e.target.value })
  };

  const onLimitChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);

    if (availableDevicesAmount && (Number(value) >= availableDevicesAmount)) {
      value = availableDevicesAmount
    }

    setCustomerData({
      ...customerData,
      deviceLimit: e.target.value === null ? null : value
    })
  }, [customerData, availableDevicesAmount]);

  const onLogoChange = useCallback((file: File | null) => {
    setCustomerData({
      ...customerData,
      logo: file
    })
  }, [customerData]);

  const onUnlimitedChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerData({ ...customerData, deviceLimit: e.target.checked ? null : 0});
    setUnlimitedDevices((state => !state));
  }, [customerData]);

  const onCustomerSelect = async (e: SelectChangeEvent<string>) => {
    const response = await getSingleCustomer(e!.target.value);

    if (response && response.status === 200) {
      setAvailableDevicesAmount(response.data.data.availableDeviceLimit as number | null);

      setCustomerData({
        ...customerData,
        parent: e!.target.value,
        deviceLimit: response.data.data.availableDeviceLimit as number | null
      })
    }
    setUnlimitedDevices(false);
  };

  const onModalWindowClose = useCallback(() => {
    handleClose();
    setCustomerData(initialCreateCustomerState);
  }, [handleClose]);

  const resetRequestError = () => {
    setRequestError('');
  };

  const onSubmit = useCallback(() => {
    const createSingleCustomer = async () => {
      try {
        setIsLoading(true);

        const response = await createCustomer({...customerData});

        if (response.data.data && response.status === 200) {
          onFormSubmit();
          onModalWindowClose();
          requestError && resetRequestError();
        }
      } catch (error: any) {
        setRequestError(error?.error.message);
      } finally {
        setIsLoading(false);
      }
    };

    createSingleCustomer();
  }, [customerData, requestError, onFormSubmit, onModalWindowClose]);

  return (
    <CustomModal
      title={MODAL_TITLE}
      isLoading={isLoading}
      isOpen={open}
      isSubmitDisabled={isDisabledBtn}
      handleClose={handleClose}
      onSubmit={onSubmit}
    >
      <FormControl
        component='form'
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>

        {requestError && (
          <Alert
            severity='error'
            onClose={resetRequestError}
          >
            {requestError}
          </Alert>)}

        <UploadLogo
          onLogoChange={onLogoChange}
          logoUrl={logoUrl}
        />

        <TextField
          required
          fullWidth
          name='name'
          label='Name'
          type='text'
          id='name'
          autoComplete='none'
          variant='standard'
          margin='dense'
          InputLabelProps={{
            sx: {
              span: {
                color: 'red',
              },
            },
          }}
          onChange={onNameChange}
          value={customerData.name}
          sx={{marginBottom: '12px'}}
        />

        <Box sx={{marginBottom: '20px', width: '100%'}}>
          <FormSelect
            required={false}
            label='Customer'
            onChange={onCustomerSelect}
            value={customerData.parent}
            values={convertedCustomers}
          />
        </Box>

        <DevicesAmountForm
          isUnlimitedDevices={unlimitedDevices}
          availableDevices={availableDevicesAmount}
          deviceLimit={customerData.deviceLimit}
          onLimitChange={onLimitChange}
          onUnlimitedChange={onUnlimitedChange}
        />
      </FormControl>
    </CustomModal>
  );
};

export default React.memo(CustomerCreateModal);
