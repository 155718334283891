import { IError } from '../../../global/types/types';
import { Reducer } from 'redux';
import {
  DEVICES_LOADING,
  DEVICES_MESSAGE,
  GET_DEVICES_LIST_FAILURE,
  GET_DEVICES_LIST_SUCCESS,
  GET_DEVICES_TAGS_FAILURE,
  GET_DEVICES_TAGS_SUCCESS,
} from '../../actions';
import { TDevicesActionsTypes } from '../../actions/devicesActionCreators/actionCreators';
import { IDevice, IDeviceTag, IDeviceType } from '../../../services/devicesService';
import {
  DEVICES_TYPES_LOADING,
  GET_DEVICES_TYPES_FAILURE,
  GET_DEVICES_TYPES_SUCCESS,
} from '../../actions/devicesTypesActionCreators/actions';
import { TDevicesTypesActionsTypes } from '../../actions/devicesTypesActionCreators/actionCreators';
import { TDevicesTagsActionsTypes } from '../../actions/devicesTagsActionCreators/actionCreators';

interface IInitialDevicesState {
  devicesTags: {
    tags: { allTags: IDeviceTag[] | []; ownTags: IDeviceTag[] | [] };
    isLoading: boolean;
    error: null | string | IError;
  };
  devicesTypes: { types: IDeviceType[] | []; isLoading: boolean; error: null | string | IError };
  devices: IDevice[] | [];
  error: null | string | IError;
  isMessage: string;
  isLoading: boolean;
  page: number;
  pageAmount: number;
  elementsAmount: number;
}

const initialState: IInitialDevicesState = {
  devicesTags: { tags: { allTags: [], ownTags: [] }, isLoading: false, error: null },
  devicesTypes: { types: [], isLoading: false, error: null },
  devices: [],
  error: null,
  isMessage: '',
  isLoading: false,
  page: 0,
  pageAmount: 0,
  elementsAmount: 0,
};

const devicesReducer: Reducer<
  IInitialDevicesState,
  TDevicesActionsTypes | TDevicesTypesActionsTypes | TDevicesTagsActionsTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICES_LIST_SUCCESS:
      return {
        ...state,
        page: action.payload.page,
        pageAmount: action.payload.pageAmount,
        elementsAmount: action.payload.elementsAmount,
        error: null,
        devices: action.payload.devices,
      };
    case GET_DEVICES_TAGS_SUCCESS:
      return {
        ...state,
        devicesTags: { ...state.devicesTags, tags: action.payload, error: null },
      };
    case GET_DEVICES_TYPES_SUCCESS:
      return {
        ...state,
        devicesTypes: { ...state.devicesTypes, types: action.payload, error: null },
      };
    case GET_DEVICES_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_DEVICES_TAGS_FAILURE:
      return {
        ...state,
        devicesTags: { ...state.devicesTags, error: action.payload },
      };
    case GET_DEVICES_TYPES_FAILURE:
      return {
        ...state,
        devicesTypes: { ...state.devicesTypes, error: action.payload },
      };
    case DEVICES_LOADING:
      return { ...state, isLoading: action.payload };
    case DEVICES_MESSAGE:
      return { ...state, isMessage: action.payload };
    // case DEVICES_TAGS_LOADING:
    //   return {
    //     ...state,
    //     devicesTags: { ...state.devicesTags, isLoading: action.payload },
    //   };

    case DEVICES_TYPES_LOADING:
      return {
        ...state,
        devicesTypes: { ...state.devicesTypes, isLoading: action.payload },
      };
    default:
      return state;
  }
};

export default devicesReducer;
