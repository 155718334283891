import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_SUCCESS, AUTH_LOADING } from '../../actions';
import { TAuthActionTypes } from '../../actions/authActionCreators/actionCreators';
import { IDataObject, IError } from '../../../global/types/types';

interface IInitialAuthState {
  user: IDataObject | null;
  isAuth: boolean;
  error: null | string | IError;
  isLoading: boolean;
}

const initialState: IInitialAuthState = { user: null, isAuth: false, error: null, isLoading: false };

const authReducer = (state: IInitialAuthState = initialState, action: TAuthActionTypes): IInitialAuthState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, user: { ...action.payload }, isAuth: true, error: null };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload };
    case LOGOUT_SUCCESS:
      return initialState;
    case AUTH_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
