import { IDataObject, IError, ILoginUserData, IUserInputData } from '../../../global/types/types';
import { LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT, LOGOUT_SUCCESS, AUTH_LOADING } from './actions';

export interface ILoginAction {
  type: typeof LOGIN;
  payload: IUserInputData;
}

export interface ILogoutAction {
  type: typeof LOGOUT;
}

export interface ILoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: ILoginUserData;
}

export interface ILoginFailureAction {
  type: typeof LOGIN_FAILURE;
  payload: string | IError;
}

export interface ILogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export interface IAuthLoadingAction {
  type: typeof AUTH_LOADING;
  payload: boolean;
}

export type TAuthActionTypes =
  | ILoginAction
  | ILogoutAction
  | ILoginSuccessAction
  | ILoginFailureAction
  | ILogoutSuccessAction
  | IAuthLoadingAction;

export const loginRequestAction = (credentials: IUserInputData) => {
  return {
    type: LOGIN,
    payload: credentials,
  };
};

export const loginRequestSuccess = (loginUserData: IDataObject) => {
  return {
    type: LOGIN_SUCCESS,
    payload: loginUserData,
  };
};

export const loginRequestFailure = (error: string | IError) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

export const logoutRequestSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const logoutRequestAction = () => {
  return {
    type: LOGOUT,
  };
};

export const authLoading = (isLoading: boolean) => {
  return {
    type: AUTH_LOADING,
    payload: isLoading,
  };
};
