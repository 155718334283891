import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Grid, Skeleton } from '@mui/material';
import { editOutput, getSingleOutput, IOutput } from '../../services/outputsService';
import { EditedList } from '../../components/EditedList';
import { roleSelector, userIdSelector } from '../../redux/selectors/authSelector';
import { IEditedListItem, Roles } from '../../global/types/types';
import EditCard from '../../components/EditCard';
import { updatedSingleCustomer, convertFunction } from '../../utils';
import DropdownSelect from '../../components/Select';
import BackButton from '../../components/BackButton';
import { outputStatuses } from '../../global/constants/outputs';
import validator from 'validator';


const SingleOutputPage = () => {
  const { id } = useParams();
  const role = useSelector(roleSelector);
  const authorizedUserId = useSelector(userIdSelector);

  const [user, setOutput] = useState<IOutput | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState('');
  const [fieldList, setFieldList] = useState<IEditedListItem[]>([]);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);
  const statuses = useMemo(() => convertFunction(outputStatuses), []);

  const convertToValidData = useCallback((outputData: IOutput) => {
    return [
      {
        title: 'Name',
        info: outputData?.name,
        fieldName: 'name',
        isEditable: true,
      },
      {
        title: 'Type',
        info: outputData?.type,
        fieldName: 'type',
        isEditable: false,
      },
      {
        title: 'Customer Name',
        info: outputData?.company.name,
        isEditable: false,
      },
      {
        title: 'URL',
        fieldName: 'url',
        info: outputData?.settings.url,
        isEditable: true,
      },
      {
        title: 'Status',
        info: outputData?.status,
        Component: DropdownSelect,
        fieldName: 'status',
        selectValues: statuses,
        isEditable: true,
      },
      {
        title: 'HTTP Header Name',
        fieldName: 'header_name',
        info: outputData?.settings.header_name,
        isEditable: true,
      },
      {
        title: 'HTTP Header Value',
        fieldName: 'header_value',
        info: outputData?.settings.header_value,
        isEditable: true,
      },
      {
        title: 'Last Response from output recipient',
        fieldName: 'last_response',
        info: outputData?.last_response,
        isEditable: false,
      },
    ];
  }, []);

  useEffect(() => {
    const fetchSingleOutput = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleOutput(id as string);

        if (response && response.status === 200) {
          setOutput(response.data.data);
          setFieldList(convertToValidData(response.data.data));
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleOutput();
  }, [convertToValidData, id]);

  const toggleEditModeHandle = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const submitHandle = useCallback(async () => {
    try {
      setIsLoading(true);
      //console.log(`fieldList is: ${JSON.stringify(fieldList)}`);
      const updatedData = updatedSingleCustomer(fieldList);
      const theUrl = updatedData.url ? updatedData.url.toString(): "";
      if (!validator.isURL(theUrl)) { 
        throw  new Error('Please enter a valid URL'); 
      }
      const theHeaderName = updatedData.header_name ? updatedData.header_name.toString(): "";
      if (theHeaderName != "") {
        if (!/^[0-9a-zA-Z_-]+$/g.test(theHeaderName)) {
          throw  new Error('Invalid HTTP Header name. Allowed characters are a-z, A-Z, _, -');
        }
      }
      //console.log(`updatedData is: ${JSON.stringify(updatedData)}`);
      const response = await editOutput(id as string, {
        name: updatedData.name ? updatedData.name.toString() : null,
        status: updatedData.status ? updatedData.status.toString() : null,
        settings: {"url": updatedData.url ? updatedData.url.toString() : "", "header_name": updatedData.header_name ? updatedData.header_name.toString() : "", "header_value": updatedData.header_value ? updatedData.header_value.toString() : ""},
      });

      if (response && response.status === 200) {
        setOutput(response.data.data);
        setRequestError('');
        toggleEditModeHandle();
      }
    } catch (e: any) {
      if ('error' in e) {
          setRequestError(e.error.message);
      } else {
        setRequestError(e.message);
      }
    } finally {
      setIsLoading(false);
      //toggleEditModeHandle();
    }
  }, [id, fieldList, toggleEditModeHandle]);

  const isIdMatched = useMemo(() => {
    return id === authorizedUserId;
  }, [id, authorizedUserId]);

  return (
    <Grid container>
      {isLoading ? (
        <Skeleton variant='rectangular' width={490} height={568} sx={{ borderRadius: '4px' }} />
      ) : (
        <>
          <BackButton to='/outputs' style={{ marginBottom: '20px' }} />

          {user && (
            <Grid container>
              {requestError && (
                <Grid container justifyContent='flex-start' alignItems='center' marginTop={2}>
                  <Alert severity='error'>{requestError}. Output was not edited</Alert>
                </Grid>
              )}
              <EditCard
                isEdit={isEdit}
                isEditable={role.name !== Roles.USER || isIdMatched}
                errors={errors}
                toggleEditModeHandle={toggleEditModeHandle}
                onSubmitHandle={submitHandle}
              >
                <EditedList fieldList={fieldList} isEdit={isEdit} setErrors={setErrors} setFieldList={setFieldList} />
              </EditCard>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default SingleOutputPage;
