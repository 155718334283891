import { IError } from '../../../global/types/types';
import { GET_OUTPUTS_FAILURE, GET_OUTPUTS_SUCCESS, GET_OUTPUTS_LOADING } from '../../actions';
import { TOutputsActionTypes } from '../../actions/outputsActionCreators/actionCreators';
import { IOutput } from '../../../services/outputsService';

interface IInitialOutputsState {
  outputs: IOutput[];
  error: null | IError | string;
  isLoading: boolean;
  page: number;
  pageAmount: number;
  elementsAmount: number;
}

const initialState: IInitialOutputsState = {
  outputs: [],
  error: null,
  isLoading: false,
  page: 0,
  pageAmount: 0,
  elementsAmount: 0,
};

const outputsReducer = (state: IInitialOutputsState = initialState, action: TOutputsActionTypes): IInitialOutputsState => {
  switch (action.type) {
    case GET_OUTPUTS_SUCCESS:
      return {
        ...state,
        outputs: action.payload.outputs,
        page: action.payload.page,
        pageAmount: action.payload.pageAmount,
        elementsAmount: action.payload.elementsAmount,
        error: null,
      };
    case GET_OUTPUTS_FAILURE:
      return { ...state, error: action.payload };
    case GET_OUTPUTS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default outputsReducer;
