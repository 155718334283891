import React, { useState, useEffect } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import DeviceDetails from '../../components/DeviceDetails';
import DeviceData from '../../components/DeviceData';
import BackButton from '../../components/BackButton';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { devicesActions } from '../../redux/actions/devicesActionCreators/actionCreators';


const tabProps = (index: number) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const SingleDevicePage = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  //  clear the alert if one was set from a previous action
  const location = useLocation();
  useEffect(() => {
    dispatch(devicesActions.setDevicesMessage(''))
  }, [location]);


  return (
    <>
      <BackButton to='/devices' style={{ marginBottom: '20px' }} />
      <Grid container>
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable tabs'
          >
            <Tab label='Device Details' {...tabProps(0)} />
            <Tab label='Device Data' {...tabProps(1)} />
          </Tabs>
          {value === 0 && <DeviceDetails />}
          {value === 1 && <DeviceData />}
        </>
      </Grid>
    </>
  );
};

export default SingleDevicePage;
