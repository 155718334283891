import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { GET_DEVICES_LIST, GET_DEVICES_TAGS } from '../../actions';
import { devicesActions } from '../../actions/devicesActionCreators/actionCreators';
import { getDevices } from '../../../services';
import {
  getDevicesTypes,
  getDeviceTags,
  IDeviceResponse,
  IDeviceTagResponse,
  IDeviceTypeResponse,
} from '../../../services/devicesService';
import { devicesTagsActions } from '../../actions/devicesTagsActionCreators/actionCreators';
import { GET_DEVICES_TYPES } from '../../actions/devicesTypesActionCreators/actions';
import { devicesTypesActions } from '../../actions/devicesTypesActionCreators/actionCreators';

function* fetchDevicesList({
  payload: { status, sort, company, type, page, rowPerPage, neverSentData, tag, search, noDataInLast24hr },
}: ReturnType<typeof devicesActions.getDevicesList>) {
  try {
    yield put(devicesActions.setDevicesLoading(true));
    //yield put(devicesActions.setDevicesMessage('moo'))

    const response: AxiosResponse = yield call(
      getDevices,
      search,
      status,
      company,
      type,
      page,
      rowPerPage,
      neverSentData,
      sort,
      tag,
      noDataInLast24hr
    );

    if (response.data.data && response.status === 200) {
      yield put(
        devicesActions.getDevicesListSuccess({
          devices: response.data.data,
          elementsAmount: response.data.elementsAmount,
          page: response.data.page,
          pageAmount: response.data.pageAmount,
        })
      );
    }
  } catch (error) {
    const e = error as AxiosError;
    yield put(
      devicesActions.getDevicesListFailure({
        message: `${e?.message}${e?.response && e?.response.statusText && ': ' + e.response.statusText}`,
        code: Number(e?.code),
      })
    );
  } finally {
    yield put(devicesActions.setDevicesLoading(false));
  }
}

function* fetchDevicesTypes() {
  try {
    yield put(devicesTypesActions.setDevicesTypesLoading(true));

    const response: AxiosResponse<IDeviceTypeResponse> = yield call(getDevicesTypes);

    if (response.data.data && response.status === 200) {
      yield put(devicesTypesActions.getDevicesTypesSuccess(response.data.data));
    }
  } catch (error) {
    const e = error as AxiosError;
    yield put(
      devicesTypesActions.getDevicesTypesFailure({
        message: `${e?.message}${e?.response && e?.response.statusText && ': ' + e.response.statusText}`,
        code: Number(e?.code),
      })
    );
  } finally {
    yield put(devicesTypesActions.setDevicesTypesLoading(false));
  }
}

function* fetchDevicesTags() {
  try {
    yield put(devicesTagsActions.setDevicesTagsLoading(true));

    const response: AxiosResponse<IDeviceTagResponse> = yield call(getDeviceTags);

    if (response.data.data && response.status === 200) {
      yield put(devicesTagsActions.getDevicesTagsSuccess(response.data.data));
    }
  } catch (error) {
    const e = error as AxiosError;
    yield put(
      devicesTagsActions.getDevicesTagsFailure({
        message: `${e?.message}${e?.response && e?.response.statusText && ': ' + e.response.statusText}`,
        code: Number(e?.code),
      })
    );
  } finally {
    yield put(devicesTagsActions.setDevicesTagsLoading(false));
  }
}

export function* devicesSaga() {
  yield all([
    takeLatest(GET_DEVICES_LIST, fetchDevicesList),
    takeLatest(GET_DEVICES_TAGS, fetchDevicesTags),
    takeLatest(GET_DEVICES_TYPES, fetchDevicesTypes),
  ]);
}
