import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, TextField, Button, CircularProgress, Typography, Box, Container } from '@mui/material';
import { resetPassword, tokenVerification } from '../../services/passwordService';
import Notification from '../../components/Notification';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [errors, setErrors] = useState<{ confirmedPassword: string; password: string }>({
    password: '',
    confirmedPassword: '',
  });
  const [isValid, setIsValid] = useState(false);
  const [requestError, setRequestError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordChangedSuccessful, setIsPasswordChangedSuccessful] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(false);

  const location = useLocation();

  const token = useMemo(() => {
    const array = location.pathname.split('/');
    const [, , token] = array;
    return token;
  }, [location.pathname]);

  useLayoutEffect(() => {
    const verifyToken = async () => {
      try {
        setIsLoading(true);
        const response = await tokenVerification(token);

        if (response && response.status === 200) {
          setIsTokenVerified(true);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, [token]);

  const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await resetPassword(password, token);

      if (response.data && response.status === 200) {
        setIsPasswordChangedSuccessful(true);
      }
    } catch (error: any) {
      setRequestError(
        `${error?.response.data.error.message}${
          error?.response.data.error.details ? ': ' + error?.response.data.error.details : ''
        }`
      );
    } finally {
      setPassword('');
      setConfirmedPassword('');
      setIsValid(false);
      setIsLoading(false);
    }
  };

  const validatePasswordInputValue = (value: string) => {
    if (value.length >= 8) {
      setErrors((state) => ({ ...state, password: '' }));
      setIsValid(true);
    } else {
      setErrors((state) => ({
        ...state,
        password: 'This field is required. Password should be at least 8 characters long',
      }));
      setIsValid(false);
    }
  };

  const validateConfirmedPasswordInputValue = (value: string) => {
    if (!value) {
      setErrors((state) => ({ ...state, confirmedPassword: 'This field is required.' }));
      setIsValid(false);
    } else if (password === value) {
      setErrors((state) => ({ ...state, confirmedPassword: '' }));
      setIsValid(true);
    } else {
      setErrors((state) => ({ ...state, confirmedPassword: 'Must be the same as password' }));
      setIsValid(false);
    }
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    validatePasswordInputValue(e.currentTarget.value);
  };

  const changeConfirmedPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmedPassword(e.currentTarget.value);
    validateConfirmedPasswordInputValue(e.currentTarget.value);
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <>
          {isLoading ? (
            <Box sx={{ mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {!isTokenVerified ? (
                <Notification title='Page not found' redirectButton />
              ) : (
                <>
                  {isPasswordChangedSuccessful ? (
                    <Notification title='The password has been changed successfully' redirectButton />
                  ) : (
                    <>
                      <Typography component='h1' variant='h5'>
                        Reset password
                      </Typography>
                      <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {requestError && <Alert severity='error'>{requestError}</Alert>}
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          name='password'
                          label='Password'
                          type='password'
                          id='password'
                          autoComplete='none'
                          onChange={changePassword}
                          value={password}
                          {...(errors['password'] && { error: true, helperText: errors['password'] })}
                        />
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          name='confirm password'
                          label='Confirm Password'
                          type='password'
                          id='confirm password'
                          autoComplete='none'
                          onChange={changeConfirmedPassword}
                          value={confirmedPassword}
                          {...(errors['confirmedPassword'] && { error: true, helperText: errors['confirmedPassword'] })}
                        />
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          sx={{ mt: 3, mb: 2 }}
                          disabled={!isValid || password !== confirmedPassword}
                        >
                          Reset Password
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Box>
    </Container>
  );
};

export default ResetPasswordPage;
