import { IError } from '../../../global/types/types';
import { GET_USERS_FAILURE, GET_USERS_SUCCESS, GET_USERS_LOADING } from '../../actions';
import { TUsersActionTypes } from '../../actions/usersActionCreators/actionCreators';
import { IUser } from '../../../services/userService';

interface IInitialUsersState {
  users: IUser[];
  error: null | IError | string;
  isLoading: boolean;
  page: number;
  pageAmount: number;
  elementsAmount: number;
}

const initialState: IInitialUsersState = {
  users: [],
  error: null,
  isLoading: false,
  page: 0,
  pageAmount: 0,
  elementsAmount: 0,
};

const usersReducer = (state: IInitialUsersState = initialState, action: TUsersActionTypes): IInitialUsersState => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        page: action.payload.page,
        pageAmount: action.payload.pageAmount,
        elementsAmount: action.payload.elementsAmount,
        error: null,
      };
    case GET_USERS_FAILURE:
      return { ...state, error: action.payload };
    case GET_USERS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
