import axiosPrivate from '../api/axios';
import { AxiosResponse } from 'axios';
import { ICompanyObject, IRoleObject } from '../global/types/types';
import { TSortParams, TStatusParams } from '../redux/actions/usersActionCreators/actionCreators';
import { UsersTableColumns } from '../global/types/users-types';
import { convertDataToSortQueue } from '../utils';

export interface IUser {
  company: ICompanyObject;
  createdAt: Date;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: IRoleObject;
  status: string;
  updatedAt: Date;
  loginAt: Date;
  parentCompanyName: string;
}

export interface IResponse {
  message: string;
  statusCode: number;
  successful: boolean;
}

export interface ISingleUserResponse extends IResponse {
  data: IUser;
}

export interface IUserResponse {
  data: IUser[];
}

export interface ICreateUserData {
  email: string;
  firstName: string | null;
  lastName: string | null;
  company: {
    id: number | null;
  };
  role: {
    id: number | null;
  };
}

export interface IUpdateUserData {
  firstName: string | null;
  lastName: string | null;
}

export const getUsers = async (
  search: string,
  sort: { id: UsersTableColumns; sort: TSortParams }[],
  page: number,
  rowPerPage: number,
  companyId: string,
  status: TStatusParams
) => {
  return axiosPrivate.get(
    `/users/?search=${search}&${convertDataToSortQueue(
      sort
    )}page=${page}&rowPerPage=${rowPerPage}&company=${companyId}&status=${status}`
  );
};

export const getSingleUser = async (id: string): Promise<AxiosResponse<ISingleUserResponse>> => {
  return axiosPrivate.get<ISingleUserResponse>(`/users/${id}`);
};

export const createUser = async (userData: ICreateUserData): Promise<AxiosResponse<{ data: IUser }>> => {
  return axiosPrivate.post<{ data: IUser }>('/users', userData);
};

export const editUser = async (id: string, userData: IUpdateUserData): Promise<AxiosResponse<{ data: IUser }>> => {
  return axiosPrivate.put<{ data: IUser }>(`/users/${id}`, userData);
};
