import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { isAuthSelector } from '../../redux/selectors/authSelector';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const isAuth = useSelector(isAuthSelector);
  const location = useLocation();

  return isAuth ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />;
};

export default PrivateRoute;
