import axiosPrivate from '../api/axios';
import { AxiosResponse } from 'axios';
import { IResponse } from './userService';
import { ICompanyObject, IRoleObject, Roles } from '../global/types/types';

export interface ICustomersResponse extends IResponse {
  data: ICompanyObject[];
  elementsAmount: number;
  page: number;
  pageAmount: number;
}

//TODO: create generic interface IGenericUser for all users type
interface ICustomerUser {
  createdAt: Date;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  role: IRoleObject;
  status: string;
  updatedAt: Date;
  loginAt: Date;
  userType: Roles;
  companyName: string;
  logo: string | null;
}

interface ICreateCustomerData {
  name: string;
  deviceLimit: number | null;
  logo: File | null;
  parent: string;
}

interface IUpdateCustomerData {
  name: string;
  deviceLimit: number | null;
  logo: File | null;
  status: string;
}

interface ISingleCustomerResponse extends IResponse {
  data: ISingleCustomer;
}

export interface ISingleCustomer extends ICompanyObject {
  users: ICustomerUser[];
}


export const getCustomers = async (isArray: boolean, page?: number, rowPerPage?: number): Promise<AxiosResponse<ICustomersResponse>> => {
  return axiosPrivate.get<ICustomersResponse>(`/companies/${isArray && '?array=true'}${(page || page === 0) ? '&page=' + page : ''}${rowPerPage ? '&rowPerPage=' + rowPerPage : ''}`);
};

export const getSingleCustomer = async (id: string): Promise<AxiosResponse<ISingleCustomerResponse>> => {
  return axiosPrivate.get<ISingleCustomerResponse>(`/companies/${id}`);
};

export const createCustomer = async (userData: ICreateCustomerData): Promise<AxiosResponse<{ data: ICustomerUser }>> => {
  let formData = new FormData();

  formData.append("name", userData.name);
  formData.append("parent", userData.parent);
  userData.logo && formData.append("logo", userData.logo);
  formData.append("deviceLimit", userData.deviceLimit ? userData.deviceLimit.toString() : '');

  return axiosPrivate.post<{ data: ICustomerUser }>('/companies', formData);
};

export const updateSingleCustomer = async (id: string, userData: IUpdateCustomerData): Promise<AxiosResponse<{ data: ICompanyObject }>> => {
  let formData = new FormData();

  formData.append("name", userData.name);
  formData.append("status", userData.status);
  formData.append("logo", userData.logo ? userData.logo : '');
  formData.append("deviceLimit", userData.deviceLimit ? userData.deviceLimit.toString() : '');

  return axiosPrivate.put(`/companies/${id}`, formData);
};
