import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { devicesActions, TSortParams } from '../../redux/actions/devicesActionCreators/actionCreators';
import {
  allDevicesTagsSelector,
  devicesAmountSelector,
  devicesListSelector,
  devicesTablePageSelector,
  devicesTypesSelector,
  isDevicesLoadingSelector,
  isDevicesMessageSelector,
} from '../../redux/selectors/devicesSelector';
import { Alert, Box, Button, CircularProgress, Grid, SelectChangeEvent, TablePagination, Typography } from '@mui/material';
import Table, { Column } from '../../components/Table';
import AddIcon from '@mui/icons-material/Add';
import { roleSelector } from '../../redux/selectors/authSelector';
import Search from '../../components/Seach';
import DropdownSelect from '../../components/Select';
import { devicesTagsActions } from '../../redux/actions/devicesTagsActionCreators/actionCreators';
import { devicesTypesActions } from '../../redux/actions/devicesTypesActionCreators/actionCreators';
import { capitalizeFirstLetter, convertFunction } from '../../utils';
import { deviceStatuses, toggleFilter } from '../../global/constants/devices';
import { Roles } from '../../global/types/types';
import DeviceCreateModal from '../../components/DeviceCreateModal';
import { customersActions } from '../../redux/actions/customersActionCreators/actionCreators';
import { customersSelector } from '../../redux/selectors/customersSelector';
import { useLocation } from 'react-router-dom';
import { TDevicesTableColumns, DevicesColumnFields } from '../../global/types/devices-types';


export interface IDevicesTableColumn {
  id: TDevicesTableColumns;
  label: string;
  align?: 'right' | 'center' | 'left';
  style?: React.CSSProperties;
  sortOrder?: 'asc' | 'desc';
  format?: (value: number) => string;
}


const devicesColumns: IDevicesTableColumn[] = [
  { id: DevicesColumnFields.companyName, label: 'Customer Name', align: 'center', sortOrder: 'asc' },
  { id: DevicesColumnFields.deviceName, label: 'Device Name', align: 'center', sortOrder: 'asc'},
  { id: DevicesColumnFields.deviceSN, label: 'Device SN', align: 'center', sortOrder: 'asc' },
  { id: DevicesColumnFields.deviceStatus, label: 'Device Status', align: 'center', sortOrder: 'asc' },
  { id: DevicesColumnFields.deviceTags, label: 'Device Tags', align: 'center', sortOrder: 'asc' },
  { id: DevicesColumnFields.lastDataSent, label: 'Date and Time last data was sent', align: 'center', sortOrder: 'asc' },
];

interface ISortParam {
  id: TDevicesTableColumns;
  sort: TSortParams;
}

const DevicesPage = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();

  const additionalSearchParams = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(search));
  }, [search]);

  useEffect(() => {
    const dataDidNotSendLastDay = additionalSearchParams.noDataInLast24hr ?? '';
    setDoNotSentData(dataDidNotSendLastDay);
  }, [additionalSearchParams]);

  //  clear the alert if one was set from a previous action
  const location = useLocation();
  useEffect(() => {
    dispatch(devicesActions.setDevicesMessage(''))
  }, [location]);

  const devices = useSelector(devicesListSelector);
  const isLoading = useSelector(isDevicesLoadingSelector);
  const isMessage = useSelector(isDevicesMessageSelector);
  const role = useSelector(roleSelector);
  const fetchedCompanies = useSelector(customersSelector);
  const fetchedDevicesTags = useSelector(allDevicesTagsSelector);
  const fetchedDevicesTypes = useSelector(devicesTypesSelector);
  const devicesAmount = useSelector(devicesAmountSelector);
  const devicesTablePage = useSelector(devicesTablePageSelector);

  const [page, setPage] = useState(devicesTablePage);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searched, setSearched] = useState('');
  const [tagFilter, setTagFilter] = useState({ name: '', value: '' });
  const [typesFilter, setTypesFilter] = useState('');
  const [companyFilter, setCompanyFilter] = useState('');
  const [neverSentData, setNeverSentData] = useState('');
  const [doNotSentData, setDoNotSentData] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [columns, setColumns] = useState(devicesColumns);
  const [sortOrder, setSortOrder] = useState<ISortParam[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const sortParams = useMemo(() => [...sortOrder].reverse(), [sortOrder]);

  useEffect(() => {
    dispatch(
      devicesActions.getDevicesList({
        search: searched,
        status: statusFilter,
        company: companyFilter,
        type: typesFilter,
        sort: sortParams,
        page,
        rowPerPage: rowsPerPage,
        neverSentData,
        tag: tagFilter.name.toLowerCase(),
        noDataInLast24hr: doNotSentData,
      })
    );
  }, [
    dispatch,
    searched,
    statusFilter,
    companyFilter,
    typesFilter,
    page,
    rowsPerPage,
    neverSentData,
    tagFilter.name,
    doNotSentData,
    sortParams,
  ]);

  const onDeviceCreate = useCallback(() => {
    dispatch(
      devicesActions.getDevicesList({
        search: searched,
        status: statusFilter,
        company: companyFilter,
        sort: sortParams,
        type: typesFilter,
        page,
        rowPerPage: rowsPerPage,
        neverSentData,
        tag: tagFilter.name.toLowerCase(),
      })
    );
  }, [dispatch, searched, statusFilter, companyFilter, typesFilter, page, rowsPerPage, neverSentData, tagFilter.name]);

  useEffect(() => {
    dispatch(devicesTagsActions.getDevicesTags());
    dispatch(devicesTypesActions.getDevicesTypes());
    dispatch(customersActions.getCustomersList({ isArray: true }));
  }, [dispatch]);

  const companies = useMemo(
    () =>
      fetchedCompanies
        .map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() }))
        .sort((a, b) => (a.name > b.name ? 1 : -1)),
    [fetchedCompanies]
  );

  const types = useMemo(
    () => fetchedDevicesTypes.map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() })),
    [fetchedDevicesTypes]
  );

  const tags = useMemo(
    () => fetchedDevicesTags.map((item) => ({ name: capitalizeFirstLetter(item.name), value: item.id.toString() })),
    [fetchedDevicesTags]
  );

  const statuses = useMemo(() => convertFunction(deviceStatuses), []);

  const sendDataParams = useMemo(() => convertFunction(toggleFilter), []);


  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const onSortChange = (e: React.MouseEvent<HTMLSpanElement>) => {
    const columnsWithChangedSortOrder = columns.map<IDevicesTableColumn>((item) => {
      if (item.id === e.currentTarget.id) {
        const newSortOrder = [
          ...sortOrder.filter((sortParam) => sortParam.id !== item.id),
          {
            id: item.id,
            sort: item.sortOrder === 'asc' ? 'desc' : 'asc',
          },
        ] as ISortParam[];
        setSortOrder(newSortOrder);

        return {
          ...item,
          sortOrder: item.sortOrder === 'asc' ? 'desc' : 'asc',
        };
      }
      return { ...item };
    });
    setColumns(columnsWithChangedSortOrder);
  };


  const onSearchTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearched(e.currentTarget.value);
  }, []);

  const onCancelSearch = useCallback(() => {
    setSearched('');
    setPage(0);
  }, []);

  const onTagSelectChange = useCallback(
    (e: SelectChangeEvent) => {
      const tagName = tags.filter((item) => item.value === e.target.value);
      setTagFilter(tagName[0]);
      setPage(0);
    },
    [tags]
  );

  const onResetTagFilter = useCallback(() => {
    setTagFilter({ name: '', value: '' });
    setPage(0);
  }, []);

  const onCompanySelectChange = useCallback((e: SelectChangeEvent) => {
    setCompanyFilter(e.target.value);
    setPage(0);
  }, []);

  const onResetCompanyFilter = useCallback(() => {
    setCompanyFilter('');
    setPage(0);
  }, []);

  const onTypeSelectChange = useCallback((e: SelectChangeEvent) => {
    setTypesFilter(e.target.value);
    setPage(0);
  }, []);

  const onResetTypeFilter = useCallback(() => {
    setTypesFilter('');
    setPage(0);
  }, []);

  const onStatusSelectChange = useCallback((e: SelectChangeEvent) => {
    setStatusFilter(e.target.value);
    setPage(0);
  }, []);

  const onResetStatusFilter = useCallback(() => {
    setStatusFilter('');
    setPage(0);
  }, []);

  const onSendDataSelectChange = useCallback((e: SelectChangeEvent) => {
    setNeverSentData(e.target.value);
    setPage(0);
  }, []);

  const onResetSendDataFilter = useCallback(() => {
    setNeverSentData('');
    setPage(0);
  }, []);

  const onDoNotSendDataSelectChange = useCallback((e: SelectChangeEvent) => {
    setDoNotSentData(e.target.value);
    setPage(0);
  }, []);

  const onResetDoNotSendDataFilter = useCallback(() => {
    setDoNotSentData('');
    setPage(0);
  }, []);

  const onModalWindowClose = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const onModalWindowOpen = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  return (
    <Grid>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '24px',
        }}
      >
        {role.name !== Roles.USER && (
          <Button variant='contained' onClick={onModalWindowOpen} sx={{ alignSelf: 'flex-end' }}>
            Add device
            <AddIcon sx={{ marginLeft: '10px' }} />
          </Button>
        )}
        {isModalOpen && (
          <DeviceCreateModal isOpen={isModalOpen} handleClose={onModalWindowClose} onFormSubmit={onDeviceCreate} />
        )}
        {isMessage != '' && (
          <Alert onClose={() => (dispatch(devicesActions.setDevicesMessage('')))} sx={{ whiteSpace: 'pre-line', marginTop: '24px' }} severity='warning'><strong>Message:</strong> {isMessage}</Alert>
        )}
      </Grid>
      <Grid
        container
        justifyContent='flex-start'
        alignItems='baseline'
        flexDirection='column'
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '10px',
        }}
        marginBottom={1}
      >
        <Search onChange={onSearchTextChange} value={searched} cancelSearch={onCancelSearch} />
      </Grid>
      <Grid
        container
        justifyContent='flex-start'
        alignItems='stretch'
        flexDirection='column'
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '10px',
          marginBottom: '34px',
        }}
      >
        <DropdownSelect
          value={tagFilter.value}
          values={tags}
          onChange={onTagSelectChange}
          dataType='Tag'
          resetValue={onResetTagFilter}
          style={{
            maxWidth: '375px',
          }}
        />
        <DropdownSelect
          value={companyFilter}
          values={companies}
          onChange={onCompanySelectChange}
          dataType='Customer Name'
          resetValue={onResetCompanyFilter}
          style={{
            maxWidth: '375px',
          }}
        />
        <DropdownSelect
          value={typesFilter}
          values={types}
          onChange={onTypeSelectChange}
          dataType='Device Type'
          resetValue={onResetTypeFilter}
          style={{
            maxWidth: '375px',
            marginTop: '10px',
          }}
        />
        <DropdownSelect
          value={neverSentData}
          values={sendDataParams}
          onChange={onSendDataSelectChange}
          dataType='Devices that never sent data'
          resetValue={onResetSendDataFilter}
          style={{
            maxWidth: '375px',
            marginTop: '10px',
          }}
        />
        <DropdownSelect
          value={doNotSentData}
          values={sendDataParams}
          onChange={onDoNotSendDataSelectChange}
          dataType='Devices that do not sent data last 24 hours'
          resetValue={onResetDoNotSendDataFilter}
          style={{
            maxWidth: '375px',
            marginTop: '10px',
          }}
        />
        <DropdownSelect
          value={statusFilter}
          values={statuses}
          onChange={onStatusSelectChange}
          dataType='Device Status'
          resetValue={onResetStatusFilter}
          style={{
            maxWidth: '375px',
            marginTop: '10px',
          }}
        />
      </Grid>

      <Grid container justifyContent='center' alignItems='center'>
        {isLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' marginTop={10}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!!devices.length ? (
              <>
                <Table 
                  link='devices' 
                  rows={devices} 
                  columns={columns} 
                  onSorting={onSortChange}
                  enableToSort
                />
                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  component='div'
                  count={devicesAmount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Typography variant='h5' margin={2}>
                No devices to display
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DevicesPage;
