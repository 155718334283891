export enum DevicesColumnFields {
    companyName = 'companyName',
    deviceName = 'name',
    deviceSN = 'serial',
    deviceStatus = 'status',
    deviceTags = 'tags',
    lastDataSent = 'lastRequestAt',
  }
  
  export type TDevicesTableColumns =
    | DevicesColumnFields.companyName
    | DevicesColumnFields.deviceName
    | DevicesColumnFields.deviceSN
    | DevicesColumnFields.deviceStatus
    | DevicesColumnFields.deviceTags
    | DevicesColumnFields.lastDataSent;