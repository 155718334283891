import axiosPrivate from '../api/axios';
import { ICompanyObject } from '../global/types/types';
import { usersStatuses } from '../global/constants/users';
import { AxiosResponse } from 'axios';
import { TDevicesTableColumns } from '../global/types/devices-types';
import { TSortParams } from '../redux/actions/devicesActionCreators/actionCreators';
import { convertDeviceDataToSortQueue } from '../utils';



interface ITemplateDataStructure {
  id: number;
  name: string;
}

export interface IDevice {
  isEditable?: boolean;
  company: ICompanyObject;
  companyName: string;
  createdAt: Date;
  description: null | string;
  deviceType: string;
  id: string;
  lastRequestAt: Date;
  manufacturer: ITemplateDataStructure;
  name: string;
  serial: string;
  status: keyof typeof usersStatuses;
  tags: ITemplateDataStructure[];
  type: { code: string; id: number; manufacturer: ITemplateDataStructure; name: string };
  updatedAt: Date;
  nextRequestAt: Date;
}

export interface IDeviceData {
  name: string;
  serial: string;
  description: null | string;
  status: keyof typeof usersStatuses;
  company: ITemplateDataStructure;
  type: ITemplateDataStructure;
  tagNames: string[];
  manufacturer: ITemplateDataStructure;
}

export interface IDeviceResponse {
  data: IDevice[];
  elementsAmount: number;
  page: number;
  pageAmount: number;
}

export interface IDeviceType {
  code: string;
  id: number;
  manufacturer: ITemplateDataStructure;
  name: string;
}

export interface IDeviceTypeResponse {
  data: IDeviceType[];
}

export interface IDeviceTag extends ITemplateDataStructure {}

export interface IDeviceTagResponse {
  data: { allTags: IDeviceTag[]; ownTags: IDeviceTag[] };
}

export interface ICreateDeviceFields {
  name: null | string;
  serial: string;
  description: null | string;
  status: string;
  company: {
    id: string | null;
  };
  type: {
    id: string | null;
  };
  tagNames: string[] | [];
}

interface ISingleDeviceResponse {
  data: IDevice;
}

export interface ISingleDeviceData {
  id: string;
  datetime: Date | string;
  field: string;
  value: string | number;
}

interface ISingleDeviceDataResponse {
  data: ISingleDeviceData[];
  page: number;
  pageAmount: number;
  elementsAmount: number;
}

export const getDevices = async (
  search: string,
  status: string,
  company: string,
  type: string,
  page: number,
  rowPerPage: number,
  neverSentData: string,
  sort: { id: TDevicesTableColumns; sort: TSortParams }[],
  tag: string,
  noDataInLast24hr: string = ''
) => {
  return axiosPrivate.get(
    `/devices/?search=${search}&${convertDeviceDataToSortQueue(sort)}page=${page}&rowPerPage=${rowPerPage}&company=${company}&type=${type}&status=${status}&neverSentData=${neverSentData}&tag=${tag}&noDataInLast24hr=${noDataInLast24hr}`
  );
};

export const getDevicesTypes = async (): Promise<AxiosResponse<IDeviceTypeResponse>> => {
  return axiosPrivate.get<IDeviceTypeResponse>('/device-types');
};

export const getDevicesManufacturers = async (): Promise<AxiosResponse> => {
  return axiosPrivate.get('/device-manufacturers');
};

export const getDeviceTags = async (): Promise<AxiosResponse<IDeviceTagResponse>> => {
  return axiosPrivate.get<IDeviceTagResponse>(`/device-tags`);
};

export const getSingleDevice = async (id: string): Promise<AxiosResponse<ISingleDeviceResponse>> => {
  return axiosPrivate.get<ISingleDeviceResponse>(`/devices/${id}`);
};

export const getSingleDeviceData = async (
  id: string,
  rowPerPage: number,
  page: number,
  start: string | Date,
  stop: string | Date
): Promise<AxiosResponse<ISingleDeviceDataResponse>> => {
  return axiosPrivate.get<ISingleDeviceDataResponse>(
    `/device-data/${id}/?rowPerPage=${rowPerPage}&page=${page}&start=${start}&stop=${stop}`
  );
};

// `/device-data/${id}/?rowPerPage=${rowPerPage}&page=${page}&start=${start}&stop=${stop}`

export const editSingleDevice = async (id: string, deviceData: any): Promise<AxiosResponse<{ data: IDevice }>> => {
  return axiosPrivate.put<{ data: IDevice }>(`/devices/${id}`, deviceData);
};

export const createDevice = async (deviceData: ICreateDeviceFields): Promise<AxiosResponse> => {
  return axiosPrivate.post('/devices', deviceData);
};

export const newDevicePassword = async (serial: string): Promise<AxiosResponse> => {
  return axiosPrivate.get(`/devices/new-password/${serial}`);
};