import React, { useEffect, useMemo, useState } from 'react';
import {
  getCountDevices,
  getCountDisconnectedDevices,
  getCountMessagesFromDevices,
  getCountNewDevices,
  IDeviceCountUnit,
} from '../../services/widgetService';
import ScrollableDataTable, { TFooter } from '../../components/ScrollableDataTable';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';

export enum DevicesCountColumnFields {
  companyName = 'companyName',
  value = 'value',
}

export type TDevicesCountTableColumns = DevicesCountColumnFields.companyName | DevicesCountColumnFields.value;

export interface IDevicesCountTableColumn {
  id: TDevicesCountTableColumns;
  label: string;
  align?: 'right' | 'center' | 'left';
  style?: React.CSSProperties;
  format?: (value: number) => string;
}

const devicesCountColumns: IDevicesCountTableColumn[] = [
  {
    id: DevicesCountColumnFields.companyName,
    label: 'Customer',
    align: 'center',
  },
  {
    id: DevicesCountColumnFields.value,
    label: 'Number of devices',
    align: 'center',
  },
];

export enum DevicesInfoColumnFields {
  title = 'title',
  data = 'data',
}

export type TDevicesInfoTableColumns = DevicesInfoColumnFields.title | DevicesInfoColumnFields.data;

export interface IDevicesInfoTableColumn {
  id: TDevicesInfoTableColumns;
  label: string;
  align?: 'right' | 'center' | 'left';
  style?: React.CSSProperties;
  format?: (value: number) => string;
}

const devicesInfoColumns: IDevicesInfoTableColumn[] = [
  {
    id: DevicesInfoColumnFields.title,
    label: 'Field Name',
    align: 'left',
  },
  {
    id: DevicesInfoColumnFields.data,
    label: 'Value',
    align: 'left',
  },
];

const DashboardPage = () => {
  const [devicesCount, setDevicesCount] = useState<IDeviceCountUnit[] | []>([]);
  const [childrenCompanyAmount, setChildrenCompanyAmount] = useState(0);
  const [childrenCompanyDevicesAmount, setChildrenCompanyDevicesAmount] = useState(0);
  const [newDevicesAmount, setNewDevicesAmount] = useState(0);
  const [disconnectedDevicesAmount, setDisconnectedDevicesAmount] = useState(0);
  //const [messagesFromDevicesAmount, setMessagesFromDevicesAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const footerData: TFooter = useMemo(() => {
    return [
      {
        id: childrenCompanyAmount,
        companyName: childrenCompanyAmount,
        value: childrenCompanyDevicesAmount,
      },
    ];
  }, [childrenCompanyDevicesAmount, childrenCompanyAmount]);

  const processedStylesRows = useMemo(() => {
    return devicesCount.map((item) => ({
      ...item,
      style: item.isParentCompany ? { background: 'rgba(25, 118, 210, .3)' } : {},
    }));
  }, [devicesCount]);

  useEffect(() => {
    const fetchDevicesCount = async () => {
      try {
        setIsLoading(true);

        const [
          countDevicesResponse,
          countNewDevicesResponse,
          countDisconnectedDevicesResponse,
          //countMessagesFromDevicesResponse,
        ] = await Promise.all([
          getCountDevices(),
          getCountNewDevices(),
          getCountDisconnectedDevices(),
          //getCountMessagesFromDevices(),
        ]);

        if (countDevicesResponse.data && countDevicesResponse.status === 200) {
          setDevicesCount(countDevicesResponse.data.data.inDetail);
          setChildrenCompanyDevicesAmount(countDevicesResponse.data.data.totalAmount);
          setChildrenCompanyAmount(countDevicesResponse.data.data.customerCompaniesAmount);
        }

        if (countNewDevicesResponse.data && countNewDevicesResponse.status === 200) {
          setNewDevicesAmount(countNewDevicesResponse.data.data.totalAmount);
        }

        if (countDisconnectedDevicesResponse.data && countDisconnectedDevicesResponse.status === 200) {
          setDisconnectedDevicesAmount(countDisconnectedDevicesResponse.data.data.totalAmount);
        }
        //if (countMessagesFromDevicesResponse.data && countMessagesFromDevicesResponse.status === 200) {
        //  setMessagesFromDevicesAmount(countMessagesFromDevicesResponse.data.data);
        //}
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDevicesCount();
  }, []);

  const eventsData = useMemo(
    () => [
      {
        title: 'New devices added this month',
        data: newDevicesAmount,
        id: newDevicesAmount.toString(),
        link: null,
        tooltipLabel: '',
      },
      {
        title: 'Disconnected Devices',
        data: disconnectedDevicesAmount,
        id: disconnectedDevicesAmount.toString(),
        link: { pathname: '/devices', search: '?noDataInLast24hr=true' },
        tooltipLabel:
          'Count of Devices - belonging to both your company and all customer companies - that haven’t sent any data in more than 24 hours.',
      },
      //{
      //  title: 'Data records this month',
      //  data: messagesFromDevicesAmount,
      //  id: messagesFromDevicesAmount.toString(),
      //  link: null,
      //  tooltipLabel: '',
      //},
    ],
    //[newDevicesAmount, disconnectedDevicesAmount], messagesFromDevicesAmount]
    [newDevicesAmount, disconnectedDevicesAmount]
  );

  return (
    <>
      <Grid
        container
        justifyContent='flex-start'
        alignItems='center'
        padding={'10px'}
        style={{ display: 'grid', gridTemplateColumns: '1fr' }}
      >
        {isLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' marginTop={15}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!!devicesCount.length ? (
              <>
                <ScrollableDataTable
                  rows={processedStylesRows}
                  columns={devicesCountColumns}
                  footerData={footerData}
                  tableHeight='425px'
                  isHeaderViewed
                />
              </>
            ) : (
              <Typography variant='h5' margin={2}>
                No data to display
              </Typography>
            )}

            <ScrollableDataTable
              title='Events'
              rows={eventsData}
              columns={devicesInfoColumns}
              style={{ marginTop: '30px' }}
            />
          </>
        )}
      </Grid>
    </>
  );
};

export default DashboardPage;
