import { RootState } from '../../store/store';
import { ICompanyObject, IError, IRoleObject } from '../../../global/types/types';

export const isAuthSelector = (state: RootState): boolean => state?.auth?.isAuth;
export const isAuthLoadingSelector = (state: RootState): boolean => state?.auth?.isLoading;
export const authErrorSelector = (state: RootState) => state?.auth?.error as string | IError;
export const roleSelector = (state: RootState): IRoleObject => state?.auth?.user?.role as IRoleObject;
export const tokenSelector = (state: RootState): string => state?.auth?.user?.token as string;
export const emailSelector = (state: RootState): string => state?.auth?.user?.email as string;
export const userIdSelector = (state: RootState): string => state?.auth?.user?.id as string;
export const availableDevicesSelector = (state: RootState): number | null =>
  state?.auth?.user?.company.availableDeviceLimit as number | null;
export const userCompanySelector = (state: RootState): ICompanyObject | null =>
  state?.auth?.user?.company as ICompanyObject | null;
