import React, { useState } from 'react';

const isEmailValid = (email: string) => {
  if (email) {
    const isValidEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
    if (isValidEmail) {
      return '';
    } else {
      return 'Email is not valid.';
    }
  }
};

const isFieldRequired = (fieldValue: string) => {
  return fieldValue.length ? '' : 'This field is required.';
};

const isPasswordFieldValid = (fieldValue: string) => {
  return fieldValue.length >= 8 ? '' : 'This field is required. Password should be at least 8 characters long';
};

const validations = {
  isEmailValid,
  isFieldRequired,
  isPasswordFieldValid,
};

export type TValidationType = keyof typeof validations;

const validate = (validateParams: TValidationType[], value: string) => {
  const error = validateParams.reduce((acc, item) => {
    const validator = validations[item];
    return validator(value) ? acc + validator(value) : acc;
  }, '');
  const isValid = !error.length;
  return { isValid, error };
};

export const useForm = (initialValue: string, validateParams: TValidationType[]) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);
  const [isValid, setValid] = useState(false);

  const changeHandler = (event: React.ChangeEvent<any>) => {
    const value = event.type === 'change' ? event.currentTarget?.value : event.target.value;
    const { isValid, error } = validate(validateParams, value as string);
    setValue(value);
    setValid(isValid);
    setError(error);
  };

  const submitHandler = () => {
    setValue(initialValue);
    setValid(false);
    setError(null);
  };

  return {
    value,
    changeHandler,
    error,
    isValid,
    submitHandler,
  };
};
