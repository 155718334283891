import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { GET_CUSTOMER_LIST } from '../../actions';
import { ICustomersResponse } from '../../../services/customersServices';
import { getCustomers } from '../../../services';
import { customersActions } from '../../actions/customersActionCreators/actionCreators';

function* fetchCustomersList({
  payload: { page, rowPerPage },
}: ReturnType<typeof customersActions.getCustomersList>)  {
  try {
    yield put(customersActions.setCustomersLoading(true));

    const response: AxiosResponse<ICustomersResponse> = yield call(getCustomers, true, page, rowPerPage);

    if (response.data.data && response.status === 200) {
      yield put(customersActions.getCustomersListSuccess({
        customers: response.data.data,
        elementsAmount: response.data.elementsAmount,
        page: response.data.page,
        pageAmount: response.data.pageAmount,
      }));
    }
  } catch (error) {
    const e = error as AxiosError;
    yield put(
      customersActions.getCustomersListFailure({
        message: `${e?.message}${e?.response && e?.response.statusText && ': ' + e.response.statusText}`,
        code: Number(e?.code),
      })
    );
  } finally {
    yield put(customersActions.setCustomersLoading(false));
  }
}

export function* customersSaga() {
  yield all([takeLatest(GET_CUSTOMER_LIST, fetchCustomersList)]);
}
