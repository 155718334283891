import React from 'react';
import DashboardPage from '../../pages/DashboardPage';
import DevicesPage from '../../pages/DevicesPage';
import CustomersPage from '../../pages/CustomersPage';
import UsersPage from '../../pages/UsersPage';
import SingleUserPage from '../../pages/SingleUserPage';
import SingleCustomerPage from '../../pages/SingleCustomerPage';
import SingleDevicePage from '../../pages/SingleDevicePage';
import OutputsPage from '../../pages/OutputsPage';
import SingleOutputPage from '../../pages/SingleOutputPage';

export const routes = [
  { label: 'dashboard', path: '/dashboard', element: <DashboardPage />, sidebar: true },
  { label: 'devices', path: '/devices', element: <DevicesPage />, sidebar: true },
  { label: 'outputs', path: '/outputs', element: <OutputsPage />, sidebar: true},
  { label: 'customers', path: '/customers', element: <CustomersPage />, sidebar: true },
  { label: 'users', path: '/users', element: <UsersPage />, sidebar: true },
  { label: 'user', path: '/users/:id', element: <SingleUserPage />, sidebar: false },
  { label: 'device', path: '/devices/:id', element: <SingleDevicePage />, sidebar: false },
  { label: 'output', path: '/outputs/:id', element: <SingleOutputPage />, sidebar: false },
  { label: 'customer', path: '/customers/:id', element: <SingleCustomerPage />, sidebar: false,},
];
