import React from 'react';
import { Box, Typography } from '@mui/material';

const RequirementHint = () => {
  return (
    <Box sx={{ display: 'flex', marginTop: '10px' }}>
      <Typography sx={{ color: 'red', marginRight: '5px' }}>*</Typography>
      <Typography> Required field</Typography>
    </Box>
  );
};

export default RequirementHint;
