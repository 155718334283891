import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout';
import { routes } from './global/constants/routes';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import PersistLogin from './components/PersistLogin';
import CreatePasswordPage from './pages/RegisterPage';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate replace to='login' />} />
      <Route path='login' element={<LoginPage />} />
      <Route path='password-recovery' element={<ForgotPasswordPage />} />
      <Route path='password-reset/:token' element={<ResetPasswordPage />} />
      <Route path='registrate/:id' element={<CreatePasswordPage />} />

      <Route element={<PersistLogin />}>
        <Route element={<PrivateRoute />}>
          <Route element={<Layout />}>
            {routes.map((item) => {
              return <Route key={item.label} path={item.path} element={item.element} />;
            })}
          </Route>
        </Route>
      </Route>

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
