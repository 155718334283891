import React, { useState } from 'react';
import { Alert, FormControl, TextField } from '@mui/material';
import FormSelect from '../FormSelect';
import { createUser } from '../../services/userService';
import CustomModal from '../CustomModal';

interface ICreateUserInitialState {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  role: string;
}

type TFormData = {
  name: string;
  value: string;
};

interface UserCreateModalProps {
  open: boolean;
  companies: TFormData[];
  roles: TFormData[];
  onFormSubmit: () => void;
  handleClose: () => void;
}

const initialCreateUserState: ICreateUserInitialState = {
  email: '',
  firstName: '',
  lastName: '',
  company: '',
  role: '',
};

const UserCreateModal = ({ open, companies, roles, handleClose, onFormSubmit }: UserCreateModalProps) => {
  const [userData, setUserData] = useState<ICreateUserInitialState>(initialCreateUserState);
  const [isLoading, setIsLoading] = useState(false);
  const [requestError, setRequestError] = useState('');

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, email: e.target.value });
  };

  const onFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, firstName: e.target.value });
  };

  const onLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, lastName: e.target.value });
  };

  const onModalWindowClose = () => {
    handleClose();
    setUserData(initialCreateUserState);
  };

  const onSubmit = () => {
    const createSingleUser = async () => {
      try {
        setIsLoading(true);
        const response = await createUser({
          email: userData.email,
          firstName: userData.firstName ? userData.firstName : null,
          lastName: userData.lastName ? userData.lastName : null,
          company: {
            id: Number(userData.company),
          },
          role: {
            id: Number(userData.role),
          },
        });

        if (response.data.data && response.status === 200) {
          onFormSubmit();
          onModalWindowClose();
        }
      } catch (error: any) {
        setRequestError(error?.error.details);
      } finally {
        setIsLoading(false);
      }
    };

    createSingleUser();
  };

  return (
    <CustomModal
      title='Create user'
      isLoading={isLoading}
      isOpen={open}
      isSubmitDisabled={!userData.email || !userData.company || !userData.role}
      handleClose={handleClose}
      onSubmit={onSubmit}
    >
      <FormControl component='form' fullWidth>
        {requestError && <Alert severity='error'>{requestError}</Alert>}
        <FormSelect
          required
          label='Customer'
          onChange={(e) => {
            setUserData({ ...userData, company: e!.target.value });
          }}
          value={userData.company}
          values={companies}
        />
        <FormSelect
          required
          label='User Type'
          onChange={(e) => {
            setUserData({ ...userData, role: e!.target.value });
          }}
          value={userData.role}
          values={roles}
        />
        <TextField
          fullWidth
          name='firstName'
          label='First Name'
          type='text'
          id='firstName'
          autoComplete='none'
          variant='standard'
          margin='dense'
          onChange={onFirstNameChange}
          value={userData.firstName}
        />
        <TextField
          fullWidth
          name='lastName'
          label='Last Name'
          type='text'
          id='lastName'
          autoComplete='none'
          variant='standard'
          margin='dense'
          onChange={onLastNameChange}
          value={userData.lastName}
        />
        <TextField
          required
          fullWidth
          id='email'
          label='Email Address'
          type='text'
          name='email'
          autoComplete='none'
          variant='standard'
          margin='dense'
          onChange={onEmailChange}
          value={userData.email}
        />
      </FormControl>
    </CustomModal>
  );
};

export default React.memo(UserCreateModal);
