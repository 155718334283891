import { RootState } from '../../store/store';
import { IDevice, IDeviceTag, IDeviceType } from '../../../services/devicesService';

export const devicesListSelector = (state: RootState): IDevice[] | [] => state?.devices?.devices;
export const allDevicesTagsSelector = (state: RootState): IDeviceTag[] | [] =>
  state?.devices?.devicesTags?.tags?.allTags;
export const ownDevicesTagsSelector = (state: RootState): IDeviceTag[] | [] =>
  state?.devices?.devicesTags?.tags?.ownTags;
export const devicesTypesSelector = (state: RootState): IDeviceType[] | [] => state?.devices?.devicesTypes?.types;
export const isDevicesLoadingSelector = (state: RootState): boolean => state?.devices?.isLoading;
export const isDevicesMessageSelector = (state: RootState): string => state?.devices?.isMessage;
export const devicesTablePageSelector = (state: RootState): number => state?.devices?.page;
export const devicesTablePageAmountSelector = (state: RootState): number => state?.devices?.pageAmount;
export const devicesAmountSelector = (state: RootState): number => state?.devices?.elementsAmount;
