import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import { verifyAccessToken } from '../../services';
import { loginRequestSuccess } from '../../redux/actions/authActionCreators/actionCreators';
import { isAuthSelector } from '../../redux/selectors/authSelector';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');
  const isAuth = useSelector(isAuthSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (token && !isAuth) {
          const response = await verifyAccessToken();

          if (response.data.data) {
            dispatch(loginRequestSuccess(response.data.data));
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    token && !isAuth ? verifyToken() : setIsLoading(false);
  }, [dispatch, isAuth, token]);

  return (
    <>
      {isLoading ? (
        <Box sx={{ mt: 3 }} display='flex' justifyContent='center'>
          <CircularProgress />
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
